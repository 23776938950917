export const resolveStyle = (desktop, mobile) => {
    if (!window.builder_dev_mode) {
        return window.innerWidth > 640 ? desktop : mobile
    } else {
        const view = document.querySelector(".widget-view")
        if (view) {
            const { width } = view.getBoundingClientRect()
            return width > 640 ? desktop : mobile
        }
        return desktop
    }
}

export const containerBlock = () => ({
    width: "100%",
    height: "100%",
    display: "flex"
})

export const noneEvents = () => ({
    pointerEvents: "none"
})

export const allEvents = () => ({
    pointerEvents: "all"
})

export const typedWidth = ({ value, type }) => ({
    maxWidth: "100%",
    width: `${value}${type}`
})

export const typedHeight = ({ value, type }) => ({
    height: "max-content",
    minHeight: `${value}${type}`
})

const resolveIdent = (ident = [0, 0, 0, 0]) => {
    return ident.map(n => `${n}px`).join(" ")
}

export const resolvePadding = padding => {
    return {
        padding: resolveIdent(padding)
    }
}

export const resolveShadow = shadow => {
    return {
        boxShadow: shadow.hasShadow
            ? `${shadow.horizontal}px ${shadow.vertical}px ${shadow.blur}px ${shadow.color}`
            : "none"
    }
}

export const resolveMargin = margin => {
    return {
        margin: resolveIdent(margin)
    }
}

export const resolveBorder = border => ({
    borderStyle: border.style,
    borderRadius: `${border.radius}px`,
    borderColor: border.color,
    borderWidth: `${border.width}px`
})

export const resolveBackground = background => {
    let style = {
        backgroundColor: background.color
    }
    if (background.border) {
        style = {
            ...style,
            ...resolveBorder(background.border)
        }
    }

    if (background.src) {
        style = {
            ...style,
            backgroundImage: `url('${background.src}')`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center"
        }
    }

    return style
}

export const toPx = val => `${val}px`

export const resolveFont = font => ({
    fontFamily: font.family,
    fontSize: `${font.size}px`
})
