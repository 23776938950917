<template>
    <div :class="{ relative: relative, 'no-relative': !relative, isOpen: isOpen && isIpad() }" class="editor-dropdown">
        <button
            @click="open"
            :style="handlerStyle"
            :class="{ 'editor-button_padding': !noPadding, active: isOpen || isActive }"
            class="editor-button editor-button_fill"
        >
            <slot name="handler"></slot>
            <DropdownChevronIcon
                :style="{ transform: isOpen ? 'rotate(180deg)' : 'none' }"
                width="6"
                height="5"
                style="margin-left: 4px"
                v-if="chevron"
                fill="#53595c"
            />
        </button>
        <div v-show="isOpen" class="editor-modal" :class="{ iPad: isIpad(), mobile: isMobile() }">
            <div :class="!hideScroll ? 'custom-scroll' : ''">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { getPersonalSettings, updatePersonalSettings } from "@common-store/personal-settings"
import { v4 } from "uuid"
import ClickOutside from "vue-click-outside"
import DropdownChevronIcon from "@icons/DropdownChevronIcon.vue"
import checkDevice from "@mixins/checkDevice"
import { EventBus } from "~events"

export default {
    components: { DropdownChevronIcon },
    props: {
        chevron: {
            type: Boolean,
            default: false
        },
        hideScroll: {
            type: Boolean,
            default: false
        },
        isChild: {
            type: Boolean,
            default: false
        },
        isActive: {
            type: Boolean,
            default: false
        },
        relative: {
            type: Boolean,
            default: true
        },
        noPadding: {
            type: Boolean,
            default: false
        },
        saveKey: {
            type: String,
            default: ""
        },
        handlerStyle: {
            type: Object,
            default: () => {}
        },
        editor: {
            type: Object,
            default: null
        }
    },
    directives: {
        ClickOutside
    },
    mixins: [checkDevice],
    name: "EditorDropdown",
    data() {
        return {
            isOpen: false,
            uuid: v4()
        }
    },
    created() {
        EventBus.$on("open-dropdown", uuid => {
            if (this.isOpen && uuid !== this.uuid) {
                this.hide()
            }
        })
        if (this.saveKey && getPersonalSettings(this.saveKey)) {
            //this.open()
        }
    },
    methods: {
        open() {
            if (this.editor && !this.isMobile()) {
                this.editor.chain().focus()
            }

            if (!this.isChild) {
                EventBus.$emit("open-dropdown", this.uuid)
            }

            if (this.saveKey && getPersonalSettings(this.saveKey) !== !this.isOpen) {
                updatePersonalSettings(this.saveKey, !this.isOpen)
            }

            this.isOpen = !this.isOpen

            this.$nextTick(() => {
                const el = this.$el.querySelector(".editor-menu-item.active")
                if (el) {
                    const { offsetTop } = el
                    this.$el.querySelector(".custom-scroll").scrollTop = offsetTop - 10
                }
            })
        },
        hide() {
            this.isOpen = false
        }
    },
    watch: {
        isOpen(val) {
            this.$emit("handle:open", val)
        }
    }
}
</script>

<style lang="sass" scoped>
.editor-button
    display: flex
    align-items: center
.editor-dropdown
    transition: .2s
    &.l-25
        .editor-modal
            left: -25px
    &.right
        .editor-modal
            left: auto
            width: max-content
            padding: 10px
            right: -5px
        .custom-scroll
            overflow: visible
    &.l-18
        .editor-modal
            left: -18px
    &.l-16
        .editor-modal
            left: -16px
    &.min-150
        .custom-scroll
            min-width: 150px
.editor-modal
    position: absolute
    top: calc(100% + 4px)
    z-index: 99999999999999
    background: #fff
    border-bottom-left-radius: 6px
    border-bottom-right-radius: 6px
    border-top: 1px solid #d5d7d9
    left: -4px
    padding: 4px
    min-width: 100px
    transition: .2s
    .custom-scroll
        max-height: 215px
        overflow: auto
    &.iPad, &.mobile
        border-radius: 6px
        border: none
        .custom-scroll
            max-height: 125px
    &.iPad
        top: -8px
        .editor-menu-item
            height: 30px
        .custom-scroll
            display: flex
            max-width: 310px
    &.mobile
        bottom: calc(100% + 5px)
        top: auto
        &::v-deep
            .editor-modal
                filter: none
        .custom-scroll
            max-height: 175px
    .editor-menu-item
        font-size: 13px
        display: flex
        align-items: center
        font-weight: 400
        border-radius: 6px
        padding-left: 14px
        padding-right: 14px
        cursor: pointer
        height: 39px
        white-space: nowrap
        margin-bottom: 2px
        color: #151515
        background: #fff
        transition: .2s
        margin-right: 5px
        &:hover, &.active
            background: #F2F7FF
        svg
            fill: #53595c
    h1, h2, h3, h4, h5, h6
        height: auto !important
        min-height: 39px !important
        font-family: Inter, sans-serif
        line-height: 160%
        font-weight: 500
    h1
        font-size: 2.125rem !important
    h2
        font-size: 1.875rem !important
    h3
        font-size: 1.5rem !important
    h4
        font-size: 1.25rem !important
    h5
        font-size: 1.125rem !important
    h6
        font-size: 1rem !important
.no-relative
    .editor-modal
        top: 100%
        left: 0
</style>
