import { Extension } from "@tiptap/core"

export const FontSize = Extension.create({
    name: "fontSize",

    addOptions() {
        return {
            types: ["textStyle"],
            sizes: new Array(45).fill(9).map((number, index) => `${number + index + 1}px`),
            defaultSize: "16px"
        }
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    fontSize: {
                        default: this.options.defaultSize,
                        parseHTML: element => element.style.fontSize || this.options.defaultSize,
                        renderHTML: attributes => {
                            if (attributes.fontSize === this.options.defaultSize) {
                                return {}
                            }

                            return { style: `font-size: ${attributes.fontSize}` }
                        }
                    }
                }
            }
        ]
    },

    addCommands() {
        return {
            setFontSize:
                fontSize =>
                ({ chain }) => {
                    return chain().setMark("textStyle", { fontSize }).run()
                }
        }
    }
})
