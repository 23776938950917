<template>
    <div v-click-outside="hide" class="attempt-result-widget">
        <div>
            <div class="relative inline-block">
                <flex-container key="content">
                    <div class="attempt-result-widget-handler show-tooltip-on-hover">
                        <div
                            @click="
                                isOpen = !isOpen
                                loading = 'practice'
                            "
                            :class="{ disabled: loading && loading === 'practice' }"
                            class="attempt-result-widget-handler-rect"
                        >
                            <TooltipOnHover triangle-left :to-horizontal="105" :to-top="-25">
                                {{ t("course.detail.version_hint") }}
                            </TooltipOnHover>

                            <div
                                :style="{ background: getBackground(index) }"
                                :key="index"
                                v-for="index in 9"
                                class="attempt-result-widget-handler-rect-small"
                            ></div>
                        </div>
                    </div>
                    <div v-if="expertView" class="attempt-result-widget-handler ml-10">
                        <div
                            @click="
                                isOpen = !isOpen
                                loading = 'message'
                            "
                            class="attempt-result-widget-handler-rect show-tooltip-on-hover"
                            :class="{
                                'attempt-result-widget-handler-rect_message': isDisplayMessages()
                            }"
                            :data-message-count="getCommentsCount()"
                        >
                            <TooltipOnHover :to-top="-25">
                                {{ t("course.detail.version_hint_message") }}
                            </TooltipOnHover>
                            <LoaderBlock v-if="loading && loading === 'message'" :green-color="true" />
                            <Message2Icon v-else />
                        </div>
                    </div>
                </flex-container>
            </div>
        </div>
        <transition name="fade-up">
            <div ref="modal" v-if="isOpen && result" class="attempt-result-widget-modal" :style="getModalPosition()">
                <CloseButtonSm @close="hide" />

                <default-title
                    v-if="result.correct_answers.length && displayCorrectResult"
                    :size="16"
                    :line-height="21"
                    :weight="500"
                >
                    {{ t("course.practice.correct_answer") }}:
                </default-title>

                <div v-if="displayCorrectResult" class="attempt-result-widget-item-container">
                    <div v-if="result.correct_answers_grouped">
                        <div
                            :key="index"
                            v-for="(answer, index) in result.correct_answers_grouped"
                            class="attempt-result-widget-item attempt-result-widget-item_correct"
                        >
                            <p>
                                <span v-html="answer"></span>
                            </p>
                        </div>
                    </div>
                    <div v-else>
                        <div
                            :key="index"
                            v-for="(answer, index) in result.correct_answers"
                            class="attempt-result-widget-item attempt-result-widget-item_correct"
                        >
                            <p style="height: max-content">
                                <img v-if="answer.image" width="30" :src="answer.image.url" alt="" />
                                <i
                                    v-if="answer.type === 2"
                                    style="color: #3451be; font-size: 14px"
                                    v-html="answer.text"
                                ></i>
                                <span v-else v-html="answer.text"></span>
                            </p>
                        </div>
                    </div>
                </div>

                <default-title v-if="result.data" class="mt-20" :size="16" :line-height="21" :weight="500">
                    {{ t(expertView ? "course.practice.student_answers" : "quiz.answers") }}:
                </default-title>

                <div class="attempt-result-items custom-scroll">
                    <div
                        :key="item.id"
                        v-for="item in result.data"
                        class="attempt-result-widget-item"
                        :class="[
                            isLastInAttempt(item) ? '' : 'not-last',
                            displayCorrectResult
                                ? item.is_correct
                                    ? 'attempt-result-widget-item_correct'
                                    : 'attempt-result-widget-item_incorrect'
                                : ''
                        ]"
                    >
                        <p style="height: max-content">
                            <template v-if="item.text_answer">
                                <span class="block">
                                    <i
                                        v-if="
                                            result.answer &&
                                            question.type.slug !== QUESTION_TYPES.WORD_FROM_CHARS &&
                                            question.type.slug !== QUESTION_TYPES.FILL_GAPS
                                        "
                                        style="color: #3451be; font-size: 14px"
                                        v-html="inlineText(item.answer.text)"
                                    ></i>
                                    <span v-html="item.text_answer"></span>
                                </span>
                            </template>
                            <template v-else-if="item.answer">
                                <img v-if="item.answer.image" width="30" :src="item.answer.image.url" alt="" />
                                <span class="block" v-html="item.answer.text"></span>
                            </template>
                            <template v-else> &nbsp;</template>

                            <FilesNewView
                                small
                                class="mt-10"
                                v-if="item.files && item.files.length"
                                :files="item.files"
                            />

                            <span v-if="isLastInAttempt(item)" class="date">
                                {{ item.updated_at | format }}
                            </span>
                        </p>
                    </div>
                </div>

                <CommentsWidget
                    class="mt-20"
                    @add="addComment"
                    @close="hide"
                    :commentable_type="commentableType"
                    :commentable_id="commentableId"
                    :attempt_id="attemptId"
                    :public="!expertView"
                    v-if="isOpen && result.data && commentableId"
                />
            </div>
        </transition>
    </div>
</template>

<script>
import FilesNewView from "@components/Files/FilesNewView.vue"
import { QUESTION_TYPES } from "@components/Practice/PracticeQuestions/_types"
import TooltipOnHover from "@components/Tooltips/TooltipOnHover.vue"
import Message2Icon from "@icons/Message2Icon"
import ImageEnlargable from "@mixins/image-enlargable"
import ClickOutside from "vue-click-outside"
import CloseButtonSm from "../../../Buttons/CloseButtonSm"
import CommentsWidget from "./AttemptCommentsWidget.vue"
import FlexContainer from "../../../Containers/FlexContainer"
import LoaderBlock from "../../../Loaders/LoaderBlock"
import DefaultTitle from "../../../Typography/DefaultTitle"
import axios from "@libs/axios"

import moment from "moment-timezone"

import "moment/dist/locale/ru"
import "moment/dist/locale/uk"
import "moment/dist/locale/it"
import "moment/dist/locale/es"
import "moment/dist/locale/de"
import "moment/dist/locale/pl"
import "moment/dist/locale/fr"
import "moment/dist/locale/lv"

moment.locale(window.app_current_locale)

export default {
    props: {
        userId: {
            type: [Number, String],
            default: 0
        },
        lessonId: {
            type: [Number, String],
            default: 0
        },
        attemptId: {
            type: [Number, String],
            default: 0
        },
        typeShow: {
            type: [Object, String],
            default: null
        },
        questionId: {
            type: [Number, String],
            default: 0
        },
        answerGroupId: {
            type: Number,
            default: 0
        },
        statement: {
            type: Object,
            default: null
        },
        quiz: {
            type: Object,
            default: null
        },
        question: {
            type: Object,
            default: () => {}
        },
        expertView: {
            type: Boolean,
            default: false
        },
        commentable: {
            type: Object,
            default: null
        }
    },
    filters: {
        format(value) {
            return moment(value).format("DD MMMM, YYYY HH:mm")
        }
    },
    directives: {
        ClickOutside
    },
    mixins: [ImageEnlargable],
    name: "AttemptResultExpertWidget",
    components: {
        FilesNewView,
        TooltipOnHover,
        Message2Icon,
        FlexContainer,
        LoaderBlock,
        CommentsWidget,
        CloseButtonSm,
        DefaultTitle
    },
    data() {
        return {
            isOpen: false,
            result: null,
            loading: false,
            commentableId: 0,
            commentableType: "question_id",
            updateIcon: false,
            QUESTION_TYPES
        }
    },
    created() {
        if (this.answerGroupId) {
            this.commentableType = "answer_group_id"
            this.commentableId = this.answerGroupId
        } else if (this.statement && !this.answerGroupId) {
            this.commentableType = "statement_id"
            this.commentableId = this.statement.id
        } else {
            this.commentableType = "question_id"
            this.commentableId = this.question.id
        }
    },
    mounted() {
        let uri = window.location.search
        let params = new URLSearchParams(uri)

        if (this.commentableType === params.get("result_commentable_type")) {
            if (
                (this.commentableType === "question_id" &&
                    this.questionId &&
                    this.questionId == params.get("result_commentable_id")) ||
                (this.commentableType === "answer_group_id" &&
                    this.answerGroupId &&
                    this.answerGroupId == params.get("result_commentable_id")) ||
                (this.commentableType === "statement_id" &&
                    this.statement &&
                    this.statement.id == params.get("result_commentable_id"))
            ) {
                this.isOpen = true

                if (this.$route) {
                    let query = Object.assign({}, this.$route.query)
                    delete query.result_commentable_type
                    delete query.result_commentable_id

                    this.$router.replace({query})
                }
            }
        }
    },
    computed: {
        displayCorrectResult() {
            return this.expertView || this.typeShow === "only_correct_or_not" || this.typeShow === "full_information"
        }
    },
    methods: {
        getCommentsCount() {
            if (this.commentable) {
                return this.commentable.comments_count
            }
            if (this.statement) {
                return this.statement.comments_count
            }
            return this.question ? this.question.comments_count : 0
        },
        isDisplayMessages() {
            if (this.commentable) {
                return this.commentable.has_comments
            }
            if (this.statement) {
                return this.statement.has_comments
            }
            return this.question && this.question.has_comments
        },
        addComment() {
            if (this.commentable) {
                this.commentable.comments_count = (this.commentable.comments_count || 0) + 1
                this.commentable.has_comments = true
                return
            }

            if (this.statement) {
                this.statement.comments_count = (this.statement.comments_count || 0) + 1
                this.statement.has_comments = true
                return
            }

            if (this.question) {
                this.question.comments_count = (this.question.comments_count || 0) + 1
                this.question.has_comments = true
            }
        },
        isLastInAttempt(item) {
            const { quiz_attempt_id } = item

            const withSameAttemptId = this.result.data.filter(answer => answer.quiz_attempt_id === quiz_attempt_id)

            if (withSameAttemptId.length === 1) {
                return true
            }

            return withSameAttemptId.indexOf(item) === withSameAttemptId.length - 1
        },
        inlineText(html) {
            const div = document.createElement("div")
            div.innerHTML = html
            return (div.textContent || div.innerText || "") + ": "
        },
        getBackground(index) {
            index = index - 1
            let target

            if (this.answerGroupId && this.statement?.answer_groups) {
                target = this.statement.answer_groups.find(
                    answer_groups => answer_groups.id === this.answerGroupId
                )?.groups_results_versions
            } else if (this.statement && this.statement.statements_results_versions) {
                target = this.statement.statements_results_versions
            } else {
                target = this.question.results_versions
            }

            if (target) {
                if (target[index] === null) {
                    return "#A4A4A4"
                }
                if (target[index] === 2) {
                    return "#4364f6"
                }
                if (target[index] === 1) {
                    return "#15A06E"
                }
                if (target[index] === 0) {
                    return "#DD4141"
                }
            }

            return "#A4A4A4"
        },

        hide() {
            this.isOpen = false
        },

        getModalPosition() {
            const position = {
                top: "-5px",
                left: "-5px"
            }

            if (this.$refs.modal) {
                const { x, width } = this.$refs.modal.getBoundingClientRect()
                if (x + width > this.$screenWidth) {
                    position.left = "auto"
                    position.right = "-5px"
                }
            } else {
                this.$nextTick(() => {
                    this.$forceUpdate()
                })
            }

            return position
        }
    },
    watch: {
        async isOpen(val) {
            if (val && !this.result) {
                try {
                    let url = `/quiz-results?question_id=${this.questionId}&attempt_id=${this.attemptId}`

                    if (this.answerGroupId) {
                        url += `&answer_group_id=${this.answerGroupId}`
                    }

                    if (this.statement && !this.answerGroupId) {
                        url += `&statement_id=${this.statement.id}`
                    }

                    if (!this.expertView) {
                        url += `&is_student=${1}`
                    }

                    const result = await axios.get(url)
                    this.result = result.data

                    this.$nextTick(() => {
                        this.isOpen = true
                    })
                } catch (e) {
                    console.log(e)
                } finally {
                    this.loading = false
                }
            } else {
                this.loading = false
            }

            if (val) {
                setTimeout(() => {
                    this.initImages()
                })
            }
        }
    }
}
</script>

<style lang="sass">
@import "attempt-result"
</style>
