<template>
    <svg fill="#DD4141" width="10" height="10" viewBox="0 0 10 10">
        <path
            d="M8.86303 2.197C9.15592 1.9041 9.15592 1.42923 8.86303 1.13634C8.57014 0.843444 8.09526 0.843444 7.80237 1.13634L8.86303 2.197ZM1.13569 7.80302C0.842792 8.09592 0.842792 8.57079 1.13569 8.86368C1.42858 9.15658 1.90345 9.15658 2.19635 8.86368L1.13569 7.80302ZM2.19637 1.13634C1.90347 0.843444 1.4286 0.843444 1.13571 1.13634C0.842814 1.42923 0.842814 1.9041 1.13571 2.197L2.19637 1.13634ZM7.80239 8.86368C8.09529 9.15658 8.57016 9.15658 8.86305 8.86368C9.15595 8.57079 9.15595 8.09592 8.86305 7.80302L7.80239 8.86368ZM7.80237 1.13634L1.13569 7.80302L2.19635 8.86368L8.86303 2.197L7.80237 1.13634ZM1.13571 2.197L7.80239 8.86368L8.86305 7.80302L2.19637 1.13634L1.13571 2.197Z"
        />
    </svg>
</template>
<script>
export default {
    name: "CloseIcon"
}
</script>
