<template>
    <div class="color-circle">
        <button v-if="withRemove" class="tooltip-wrapper" @click.prevent.stop="$emit('remove')">
            <div class="tooltip-close tooltip-close_danger">
                <close-icon class="pointer-events-none" :noColor="true" :fill="color" />
            </div>
        </button>

        <svg
            @click="$emit('click', color)"
            class="color-circle__main"
            height="30"
            width="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="15" cy="15" r="15" stroke="#DADADA" stroke-width="1" :fill="color" />
        </svg>
        <svg
            class="color-circle__background"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_38_2134"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="30"
                height="30"
            >
                <circle cx="15" cy="15" r="15" fill="#C4C4C4" />
            </mask>
            <g mask="url(#mask0_38_2134)">
                <rect width="4" height="4" fill="white" />
                <rect y="4" width="4" height="4" fill="#E2E2E2" />
                <rect y="8" width="4" height="4" fill="white" />
                <rect y="12" width="4" height="4" fill="#E2E2E2" />
                <rect y="16" width="4" height="4" fill="white" />
                <rect y="20" width="4" height="4" fill="#E2E2E2" />
                <rect y="24" width="4" height="4" fill="white" />
                <rect y="28" width="4" height="4" fill="#E2E2E2" />
                <rect x="4" width="4" height="4" fill="#E2E2E2" />
                <rect x="4" y="4" width="4" height="4" fill="white" />
                <rect x="4" y="8" width="4" height="4" fill="#E2E2E2" />
                <rect x="4" y="12" width="4" height="4" fill="white" />
                <rect x="4" y="16" width="4" height="4" fill="#E2E2E2" />
                <rect x="4" y="20" width="4" height="4" fill="white" />
                <rect x="4" y="24" width="4" height="4" fill="#E2E2E2" />
                <rect x="4" y="28" width="4" height="4" fill="white" />
                <rect x="8" width="4" height="4" fill="white" />
                <rect x="8" y="4" width="4" height="4" fill="#E2E2E2" />
                <rect x="8" y="8" width="4" height="4" fill="white" />
                <rect x="8" y="12" width="4" height="4" fill="#E2E2E2" />
                <rect x="8" y="16" width="4" height="4" fill="white" />
                <rect x="8" y="20" width="4" height="4" fill="#E2E2E2" />
                <rect x="8" y="24" width="4" height="4" fill="white" />
                <rect x="8" y="28" width="4" height="4" fill="#E2E2E2" />
                <rect x="12" width="4" height="4" fill="#E2E2E2" />
                <rect x="12" y="4" width="4" height="4" fill="white" />
                <rect x="12" y="8" width="4" height="4" fill="#E2E2E2" />
                <rect x="12" y="12" width="4" height="4" fill="white" />
                <rect x="12" y="16" width="4" height="4" fill="#E2E2E2" />
                <rect x="12" y="20" width="4" height="4" fill="white" />
                <rect x="12" y="24" width="4" height="4" fill="#E2E2E2" />
                <rect x="12" y="28" width="4" height="4" fill="white" />
                <rect x="16" width="4" height="4" fill="white" />
                <rect x="16" y="4" width="4" height="4" fill="#E2E2E2" />
                <rect x="16" y="8" width="4" height="4" fill="white" />
                <rect x="16" y="12" width="4" height="4" fill="#E2E2E2" />
                <rect x="16" y="16" width="4" height="4" fill="white" />
                <rect x="16" y="20" width="4" height="4" fill="#E2E2E2" />
                <rect x="16" y="24" width="4" height="4" fill="white" />
                <rect x="16" y="28" width="4" height="4" fill="#E2E2E2" />
                <rect x="20" width="4" height="4" fill="#E2E2E2" />
                <rect x="20" y="4" width="4" height="4" fill="white" />
                <rect x="20" y="8" width="4" height="4" fill="#E2E2E2" />
                <rect x="20" y="12" width="4" height="4" fill="white" />
                <rect x="20" y="16" width="4" height="4" fill="#E2E2E2" />
                <rect x="20" y="20" width="4" height="4" fill="white" />
                <rect x="20" y="24" width="4" height="4" fill="#E2E2E2" />
                <rect x="20" y="28" width="4" height="4" fill="white" />
                <rect x="24" width="4" height="4" fill="white" />
                <rect x="24" y="4" width="4" height="4" fill="#E2E2E2" />
                <rect x="24" y="8" width="4" height="4" fill="white" />
                <rect x="24" y="12" width="4" height="4" fill="#E2E2E2" />
                <rect x="24" y="16" width="4" height="4" fill="white" />
                <rect x="24" y="20" width="4" height="4" fill="#E2E2E2" />
                <rect x="24" y="24" width="4" height="4" fill="white" />
                <rect x="24" y="28" width="4" height="4" fill="#E2E2E2" />
                <rect x="28" width="4" height="4" fill="#E2E2E2" />
                <rect x="28" y="4" width="4" height="4" fill="white" />
                <rect x="28" y="8" width="4" height="4" fill="#E2E2E2" />
                <rect x="28" y="12" width="4" height="4" fill="white" />
                <rect x="28" y="16" width="4" height="4" fill="#E2E2E2" />
                <rect x="28" y="20" width="4" height="4" fill="white" />
                <rect x="28" y="24" width="4" height="4" fill="#E2E2E2" />
                <rect x="28" y="28" width="4" height="4" fill="white" />
                <circle cx="15" cy="15" r="14.5" stroke="#DADADA" />
            </g>
        </svg>
    </div>
</template>

<script>
import CloseIcon from "@icons/CloseIcon.vue"

export default {
    components: { CloseIcon },
    props: {
        withRemove: Boolean,
        color: String
    },
    name: "ColorCircle"
}
</script>

<style scoped lang="sass">
.color-circle
    cursor: pointer
    width: 30px
    height: 30px
    position: relative
    border-radius: 50%
    .tooltip-wrapper
        display: none
        position: absolute
        top: -10px
        right: -6px
        z-index: 5
    &:hover
        .tooltip-wrapper
            display: block
    &__main
        position: relative
        z-index: 1
    &__background
        border-radius: 50%
        overflow: hidden
        position: absolute
        left: 0
        right: 0
        z-index: 0
</style>
