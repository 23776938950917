<template>
    <flex-container style="gap: 0.25rem" align="center">
        <editor-dropdown
            is-child
            :editor="editor"
            @handle:open="handleColorModal($event)"
            ref="color"
            hide-scroll
            :relative="false"
            no-padding
        >
            <template #handler>
                <svg
                    class="tippy"
                    :data-tippy-content="t('builder.text_color')"
                    style="border-radius: 4px"
                    :fill="color"
                    :style="{ background: isWhite('color') ? '#000' : '#fff' }"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                >
                    <path
                        class="pointer-events-none"
                        d="M15.2459 14H8.75407L7.15407 18H5L11 3H13L19 18H16.8459L15.2459 14ZM14.4459 12L12 5.88516L9.55407 12H14.4459ZM3 20H21V22H3V20Z"
                    ></path>
                </svg>
            </template>
            <template #content>
                <div>
                    <EditorColorInput
                        class="relative"
                        flat
                        :is-open="false"
                        @hide="
                            $refs.color.hide()
                            $emit('hide')
                        "
                        :editor="editor"
                        :displayed-color="color"
                        @update="updateColor"
                    />
                </div>
            </template>
        </editor-dropdown>
        <editor-dropdown
            is-child
            :editor="editor"
            @handle:open="handleColorModal($event)"
            ref="back"
            hide-scroll
            :relative="false"
            no-padding
        >
            <template #handler>
                <svg
                    class="tippy"
                    :data-tippy-content="t('builder.bg_color')"
                    style="border-radius: 4px"
                    :style="{ background: isWhite('background') ? '#000' : background }"
                    :fill="isWhite('background') ? '#fff' : '#fff'"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                >
                    <path
                        class="pointer-events-none"
                        d="M15.2459 14H8.75407L7.15407 18H5L11 3H13L19 18H16.8459L15.2459 14ZM14.4459 12L12 5.88516L9.55407 12H14.4459ZM3 20H21V22H3V20Z"
                    ></path>
                </svg>
            </template>
            <template #content>
                <div>
                    <EditorColorInput
                        :editor="editor"
                        class="relative"
                        flat
                        :is-open="false"
                        @hide="
                            $refs.back.hide()
                            $emit('hide')
                        "
                        :displayed-color="background"
                        @update="updateBackground"
                    />
                </div>
            </template>
        </editor-dropdown>
    </flex-container>
</template>

<script>
import FlexContainer from "@components/Containers/FlexContainer.vue"
import EditorColorInput from "@components/Editor/components/EditorColorInput.vue"
import EditorDropdown from "@components/Editor/components/EditorDropdown.vue"

export default {
    components: { FlexContainer, EditorColorInput, EditorDropdown },
    props: {
        editor: {
            type: Object,
            default: () => {}
        }
    },
    name: "EditorColors",
    data() {
        return {
            color: "#53595c",
            background: ""
        }
    },
    mounted() {
        setTimeout(() => {
            document.addEventListener("selectionchange", () => {
                if (this.getCurrentColor()) {
                    this.color = this.getCurrentColor().color
                    this.background = this.getCurrentColor().background

                    this.$forceUpdate()
                }
            })
        })
    },
    methods: {
        handleColorModal() {},
        isWhite(prop) {
            return (
                this[prop] === "#fff" ||
                this[prop] === "#FFF" ||
                this[prop] === "#ffffff" ||
                this[prop] === "#FFFFFF" ||
                this[prop] === "rgb(255,255,255)" ||
                this[prop] === "rgba(255,255,255, 1)" ||
                this[prop] === "rgb(255, 255, 255)"
            )
        },
        isBlack(prop) {
            return (
                this[prop] === "#000" ||
                this[prop] === "#000000" ||
                this[prop] === "rgba(0, 0, 0, 0)" ||
                this[prop] === "rgb(0,0,0)" ||
                this[prop] === "rgba(0,0,0, 1)" ||
                this[prop] === "rgb(0, 0, 0)"
            )
        },
        handleMouseDown(ev) {
            if (ev.target && ev.target.classList.contains("default-input__input")) {
                return
            }

            ev.preventDefault()
        },
        updateColor(color) {
            this.editor.chain().focus().setColor(color).run()
            setTimeout(() => {
                this.color = color
                this.$forceUpdate()
            }, 50)
        },
        updateBackground(color) {
            this.editor.chain().focus().setHighlight({ color }).run()
            setTimeout(() => {
                this.background = color
                this.$forceUpdate()
            }, 50)
        },
        getCurrentColor() {
            const selection = window.getSelection()
            if (selection && selection.anchorNode) {
                const anchor = window.getComputedStyle(selection.anchorNode.parentElement, null)
                const color = anchor.getPropertyValue("color")
                let background = anchor.getPropertyValue("background-color")

                if (selection.anchorNode.parentElement.closest(".builder-color-input")) {
                    return
                }

                if (background === "rgba(0, 0, 0, 0)") {
                    background = "#fff"
                }

                return {
                    color,
                    background
                }
            } else {
                return {
                    color: "#53595c",
                    background: "#fff"
                }
            }
        }
    }
}
</script>

<style lang="sass" scoped>

</style>
