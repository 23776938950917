export default {
    account_restored: "Account ripristinato",
    restore_account: "Recupera account",
    authorization_platform: "Accedi o registrati",
    on_your: "Al tuo",
    email: "indirizzo email",
    code_has_been_sent: "il codice di conferma è stato inviato",
    enter_code: "Inserisci il codice",
    code_is_valid: "Il codice è valido",
    minutes: "minuti",
    request_new_code: "Puoi richiedere nuovamente il codice tra",
    sec: "secondi",
    send_again: "Invia di nuovo il codice",
    back: "Indietro",
    next: "Avanti",
    enter_password: "Inserisci la password",
    repeat_password: "Ripeti la password",
    forgot_password: "Hai dimenticato la password?",
    restore_access: "Ripristina l'accesso",
    enter_username: "Inserisci il tuo indirizzo email o telefono",
    enter_email: "Inserisci il tuo indirizzo email",
    create_new_password: "Crea una nuova password",
    select_language: "Scegli la lingua",
    select_currency: "Scegli la valuta",
    language: "Lingua",
    currency: "Valuta",
    or: "o",
    enter: "Entra",
    close: "Chiudi",
    fields_filled: "Campi compilati!",
    fill_title: "Compila i campi.",
    fill_descr: "Per completare la registrazione, devi compilare i campi",
    welcome: "Benvenuto!",
    check_locale: "Dicci se abbiamo identificato correttamente la tua lingua:",
    all_ok: "Sì, è corretto",
    no_change_language: "No, cambia la lingua",
    set_password: "Impostazione della password",
    set_password_description: "Scegli una password",
    understand_thanks: "Capito, grazie!",
    prerecording: "Pre-registrazione",
    prerecording_success: "Sei stato aggiunto con successo alla lista di pre-registrazione",
    congratulations: "Congratulazioni!",
    back_to_menu: "Indietro al <b>Menu</b>",
    //
    partners_program: "Programma affiliati",
    become_a_partner: "Diventa un partner",
    products: "I miei prodotti",
    certificates: "Certificati",
    knowledge: "Base di conoscenza",
    practices: "Pratiche completate",
    dictionary: "Dizionario",
    calendar: "Calendario degli eventi",
    news: "Notizie",
    history: "Cronologia degli acquisti",
    localization: "Lingua",
    settings: "Impostazioni",
    exit: "Esci",
    projects: "I miei progetti",
    "expert.profile.expert_organization_cabinet": "Cabinet Organizzativo",
    "expert.profile.expert_producer_cabinet": "Cabinet del Produttore",
    "expert.profile.expert_cabinet": "Cabinet dell'Esperto",
    "expert.profile.student_cabinet": "Cabinet Studente",
    "expert.profile.create_cabinet": "Crea un account",
    subaccounts: "Sotto-account",
    school: "Apri la tua scuola",
    dashboard: "Pannello di controllo",
    logout: "Esci dal tuo account",
    auth_type_title: "Benvenuto!",
    auth_type_btn_1: "Imposta una password",
    auth_type_btn_2: "Mantieni il metodo di autenticazione attuale"
}
