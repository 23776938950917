<template>
    <flex-container :align="isIpad() ? 'flex-start' : 'center'">
        <editor-dropdown is-child :editor="editor" class="min-150" ref="dropdownFont">
            <template #handler>
                <p class="font-overflow" :data-tippy-content="t('builder.font')" :style="{ fontFamily: font.family }">
                    {{ fonts[font.family] || font.family }},
                </p>
            </template>
            <template #content>
                <template v-for="(fontValue, fontKey, index) in fonts">
                    <div
                        v-if="fontValue !== Object.values(fonts)[index - 1]"
                        :key="index"
                        class="editor-menu-item"
                        @click="setFontFamily(fontKey)"
                        :style="{ fontFamily: fontKey }"
                        :class="{
                            active:
                                editor.isActive('textStyle', {
                                    fontFamily: fontKey
                                }) || fontValue === fonts[font.family]
                        }"
                    >
                        {{ fontValue }}
                    </div>
                </template>
            </template>
        </editor-dropdown>
        <editor-dropdown
            is-child
            :editor="editor"
            :handler-style="{ fontSize: '14px', padding: '0 1px', marginLeft: '-4px', marginRight: '4px' }"
            class="l-25"
            ref="dropdownSize"
        >
            <template #handler>
                <p :data-tippy-content="t('builder.font_size')">{{ font.size }}</p>
            </template>
            <template #content>
                <template v-for="(fontSize, index) in sizes">
                    <div
                        :key="index"
                        class="editor-menu-item"
                        @click="setFontSize(fontSize)"
                        :class="{
                            active:
                                editor.isActive('textStyle', {
                                    fontSize: fontSize
                                }) || fontSize === font.size
                        }"
                    >
                        {{ fontSize }}
                    </div>
                </template>
            </template>
        </editor-dropdown>

        <div class="divider" style="margin: 0 0.25rem"></div>
        <editor-dropdown is-child :editor="editor" class="l-16" ref="dropdownHeight">
            <template #handler>
                <p
                    :data-tippy-content="t('builder.height')"
                    style="display: flex; align-items: center; justify-content: center"
                >
                    <svg
                        style="margin-right: 4px"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                    >
                        <path
                            d="M11 4H21V6H11V4ZM6 7V11H4V7H1L5 3L9 7H6ZM6 17H9L5 21L1 17H4V13H6V17ZM11 18H21V20H11V18ZM9 11H21V13H9V11Z"
                        ></path>
                    </svg>
                    {{ getLineHeight() }}
                </p>
            </template>
            <template #content>
                <template v-for="(line, index) in lines">
                    <div
                        :key="index"
                        class="editor-menu-item"
                        @click="setLineHeight(line)"
                        :class="{
                            active: line == parseFloat(font.height) / parseFloat(font.size)
                        }"
                    >
                        {{ line }}
                    </div>
                </template>
            </template>
        </editor-dropdown>

        <!-- <div class="divider" style="margin: 0 0.25rem"></div>

               <button @click="open" class="editor-button editor-button_fill flex items-center">
            <SettingsIcon width="22" />
        </button>-->

        <portal to="modals">
            <OverlayLayout :max-width="560" full-height :title="t('settings.settings')" @close="hide" v-if="isOpen">
                <template #right>
                    <DefaultButton @click="setGlobalFont" :text="t('expert.save')" size="x-medium" background="green" />
                </template>

                <shadow-container>
                    <DefaultDropdown
                        class="mb-12"
                        v-model="globalFont.family"
                        :cases="fonts"
                        from-cases
                        fontable
                        :label="t('builder.font_family')"
                        is-flat
                    ></DefaultDropdown>

                    <DefaultDropdown
                        class="mb-12"
                        v-model="globalFont.size"
                        :cases="sizes"
                        :label="t('builder.font_size')"
                        is-flat
                    ></DefaultDropdown>

                    <DefaultDropdown
                        v-model="globalFont.height"
                        :cases="lines"
                        :label="t('builder.height')"
                        is-flat
                    ></DefaultDropdown>
                </shadow-container>
            </OverlayLayout>
        </portal>
    </flex-container>
</template>

<script>
import { getPersonalSettings, updatePersonalSettings } from "@common-store/personal-settings"
import DefaultButton from "@components/Buttons/DefaultButton.vue"
import ShadowContainer from "@components/Containers/ShadowContainer.vue"
import EditorDropdown from "@components/Editor/components/EditorDropdown.vue"
import FlexContainer from "@components/Containers/FlexContainer.vue"
import DefaultDropdown from "@components/Forms/DefaultDropdown.vue"
import OverlayLayout from "@expert-components/Layout/OverlayLayout.vue"
import IsOpen from "@mixins/is-open"
import checkDevice from "../../../mixins/checkDevice"

export default {
    props: {
        editor: {
            type: Object,
            default: () => {}
        }
    },
    name: "EditorFont",
    components: {
        DefaultButton,
        DefaultDropdown,
        ShadowContainer,
        OverlayLayout,
        FlexContainer,
        EditorDropdown
    },
    mixins: [checkDevice, IsOpen],
    data() {
        return {
            fonts: {
                [`Lato, sans-serif`]: "Lato",
                [`ubuntu, serif`]: "Ubuntu",
                [`montserrat, sans-serif`]: "Montserrat",
                [`comfortaa, sans-serif`]: "Comfortaa",
                [`inter`]: "Inter",
                [`arial, 'helvetica neue', helvetica, sans-serif`]: "Arial",
                [`'comic sans ms', 'marker felt-thin', arial, sans-serif`]: "Comic Sans",
                [`'courier new', courier, 'lucida sans typewriter', 'lucida typewriter', monospace`]: "Courier New",
                [`georgia, times, 'times new roman', serif`]: "Georgia",
                [`'impact', 'helvetica neue', helvetica, sans-serif`]: "Impact",
                [`impact, 'helvetica neue', helvetica, sans-serif`]: "Impact",
                [`tahoma, verdana, segoe, sans-serif`]: "Tahoma",
                [`'times new roman', times, baskerville, georgia, serif`]: "Times New Roman",
                [`'trebuchet ms', 'lucida grande', 'lucida sans unicode', 'lucida sans', tahoma, sans-serif`]:
                    "Trebuchet",
                [`verdana, geneva, sans-serif`]: "Verdana",
                [`monospace, sans-serif`]: "Monospace",
                [`Oswald, sans-serif`]: "Oswald",
                [`Pacifico, sans-serif`]: "Pacifico",
                [`JetBrainsMono, sans-serif`]: "JetBrainsMono",
                [`lato, sans-serif`]: "Lato"
            },
            sizes: new Array(45).fill(9).map((number, index) => `${number + index + 1}px`),
            font: {
                family: `lato, sans-serif`,
                size: `16px`,
                height: "1.3"
            },
            lines: new Array(13).fill(7).map((number, index) => `${(number + index + 1) / 10}`),
            globalFont: null
        }
    },
    mounted() {
        document.addEventListener("selectionchange", () => {
            if (this.getCurrentFont()) {
                this.font = this.getCurrentFont()
            }

            this.$forceUpdate()
        })

        this.globalFont = getPersonalSettings("global_font_settings") || {
            family: `lato, sans-serif`,
            size: `16px`,
            height: "1.3"
        }

        /* tippy(this.$el.querySelectorAll("[data-tippy-content]"), {
            appendTo: () => this.$el,
            arrow: false
        })*/
    },
    methods: {
        setGlobalFont() {
            updatePersonalSettings("global_font_settings", this.globalFont)
        },
        getLineHeight() {
            if (!this?.font?.height || !this?.font?.size) {
                return "1.3"
            }

            const val = parseFloat(this.font.height) / parseFloat(this.font.size)

            if (Number.isNaN(val)) {
                return "1.3"
            }

            return val.toFixed(1)
        },
        setLineHeight(lineHeight) {
            this.editor.chain().focus().setLineHeight(lineHeight).run()
            this.$refs.dropdownHeight.hide()

            setTimeout(() => {
                this.font.height = parseFloat(this.font.size) * lineHeight
                this.$forceUpdate()
            }, 50)
        },
        setFontSize(fontSize) {
            this.editor.chain().focus().setFontSize(fontSize).run()
            setTimeout(() => {
                this.editor.commands.focus()
            })
            this.$refs.dropdownSize.hide()

            setTimeout(() => {
                this.font.size = fontSize
                this.$forceUpdate()
            }, 50)
        },
        setFontFamily(fontKey) {
            this.editor.chain().focus().setFontFamily(fontKey).run()
            this.$refs.dropdownFont.hide()

            setTimeout(() => {
                this.font.family = fontKey
                this.$forceUpdate()
            }, 50)
        },
        getCurrentFont() {
            const selection = window.getSelection()
            if (selection && selection.anchorNode) {
                let el = selection.anchorNode.parentElement

                if (el.closest(".editor-menu")) {
                    return
                }

                if (
                    el.classList &&
                    el.classList.contains("tiptap") &&
                    selection?.anchorNode?.firstChild &&
                    selection?.anchorNode?.firstChild instanceof HTMLElement
                ) {
                    el = selection.anchorNode.firstChild
                }

                const anchor = window.getComputedStyle(el, null)

                const family = anchor.getPropertyValue("font-family").replaceAll(`"`, `'`)
                const size = anchor.getPropertyValue("font-size").replaceAll(`"`, `'`)
                const height = anchor.getPropertyValue("line-height").replaceAll(`"`, `'`)

                return {
                    family,
                    size,
                    height
                }
            } else {
                return {
                    family: `lato, sans-serif`,
                    size: 13,
                    height: "1.3"
                }
            }
        }
    }
}
</script>

<style lang="sass" scoped>
.font-overflow
    max-width: 160px
    overflow: hidden
    text-overflow: ellipsis
</style>
