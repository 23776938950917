<template>
    <div class="color-input" :class="{ disabled, flat, tooltiped }">
        <template v-if="tooltiped">
            <alert-with-question>
                <default-title class="mb-5"> {{ label }} </default-title>
                <slot></slot>
            </alert-with-question>

            <p
                v-if="label"
                style="color: #52565c"
                :style="{ fontSize: `${14}px`, lineHeight: `${16}px`, fontWeight: 400 }"
                class="default-description"
                @click="$emit('click')"
            >
                {{ label }}:
            </p>
        </template>

        <div
            class="color-input-picker"
            :style="alertStyle()"
            :class="{ 'color-input-picker_opened': isPickerOpen || flat }"
        >
            <div class="color-input-picker__main">
                <div class="color-input__popular">
                    <ColorCircle
                        @click="
                            $emit('update', $event)
                            hideColorInput()
                        "
                        v-for="(color, index) in popular"
                        :key="index"
                        :color="color"
                    />
                </div>
                <div @click="isAdditionalOpen = !isAdditionalOpen" class="color-input__settings-handler">
                    {{ t("letter-builder.color_settings") }}
                </div>
            </div>
            <div
                class="color-input-picker__additional"
                :class="{ 'color-input-picker__additional_opened': isAdditionalOpen }"
            >
                <ChromePicker :value="displayedColor" @input="updateValue" />
            </div>
        </div>
        <div class="color-input-displayed">
            <ColorCircle @click="isPickerOpen = true" :color="displayedColor" />
        </div>

        <p
            v-if="label && !tooltiped"
            style="color: #52565c"
            :style="{ fontSize: `${14}px`, lineHeight: `${16}px`, fontWeight: 400 }"
            class="default-description"
            @click="$emit('click')"
        >
            {{ label }}
        </p>
        <div v-if="description" class="page-edit-aside-description">
            {{ description }}
        </div>
    </div>
</template>

<script>
import PopularColors from "./popular-colors"
import ColorCircle from "./ColorCircle"

import { Chrome } from "vue-color"
import AlertWithQuestion from "@components/Alerts/AlertWithQuestion.vue"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"

export default {
    name: "ColorInput",
    components: {
        DefaultTitle,
        AlertWithQuestion,
        ColorCircle,
        ChromePicker: Chrome
    },
    props: {
        fixed: {
            type: Boolean,
            default: false
        },
        displayedColor: {
            type: String,
            required: false
        },
        description: {
            type: String,
            required: false
        },
        tooltiped: {
            type: Boolean,
            required: false
        },
        label: {
            type: String,
            required: false
        },
        disabled: {
            type: Boolean,
            required: false
        },
        flat: {
            type: Boolean,
            required: false
        },
        isOpen: {
            type: Boolean,
            default: true
        }
    },
    model: {
        prop: "displayedColor",
        event: "update"
    },
    data() {
        return {
            isPickerOpen: false,
            isAdditionalOpen: this.isOpen,
            colors: "#7778A470",
            popular: PopularColors
        }
    },
    methods: {
        updateValue(event) {
            if (
                this.displayedColor === "rgba(255,255,255,0)" ||
                this.displayedColor === "rgba(0,0,0,0)" ||
                this.displayedColor === "transparent"
            ) {
                this.$emit("update", event.hex)
            } else {
                this.$emit("update", event.hex8)
            }
        },
        alertStyle() {
            const style = {}

            if (this.fixed && this.$el) {
                const { top, left } = this.$el.getBoundingClientRect()
                style.position = `fixed`
                //style.transform = "translateY(-100%)"
                style.top = `${top + 15}px !important`
                style.left = `${left}px !important`
                style.bottom = "auto"
            }
            return style
        },
        hideColorInput() {
            this.isPickerOpen = false
        }
    },
    mounted() {
        window.addEventListener("mousedown", event => {
            if (!event.target.closest(".color-input")) {
                this.hideColorInput()
            }
        })
    }
}
</script>

<style lang="sass">

.vc-chrome-saturation-wrap
    width: 150px !important
    padding: 4px 10px !important
    padding-bottom: 70% !important
    border-radius: 6px !important

.vc-chrome
    box-shadow: none !important
    width: 150px !important
    padding: 4px 10px !important
    border-left: 1px solid #E7ECF1 !important

.vc-chrome-toggle-btn
    display: none !important
.vc-chrome-body
    padding: 0 !important
    width: 150px !important
    margin-top: 10px

.vc-saturation-circle
    border: 2px solid #fff !important
    box-shadow: none !important

.vc-checkerboard
    background-image:  url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALUSURBVHgBvVdJbuJQEP04ZFIGWYmiJEJIZsOwCpyguUHnBnFOAH2ChBMEThC4AUdoTgC9YlpggYTEzmKe6fcsGwFNEwjDk8D/u6x6rvo12SbWQDqdli8vL/2TyeQntkGbzaZgLVOGtY51BssMrimPx5NcR6dtDcIQluGzszP54uJCNBoNYRIaV4fDIXq9nuh2u6LVaolOp6NBFh8Ohwmfz6dtTFwoFEJQ8H51dSXf3t4Ku91u3K9UKnPPOZ3OuX25XBYgFaPRSMM24na748v0S8tuFovFD5BGT05O5Pv7+ynpOqAnjo+PxenpqYL1Jwz4WPrc4g08+Hl9fa3e3d0JSZK+tHCVfDweC03TaH0clr/OPjdnMS3FOaq0kqTbgjpo/dHRkbpo+VQ7BCou4YeHB7FrkBxuD+dyufAccTabVXB5w5nuxNJlMHW/MVO4N6IGwfOC6FXo4m3O9Ct5rVaTkeu0+t0wDxuVKbNv3Nzc0OUhWi3l8/nn8/NzZZOU+S541nC3jGLkl/AGQVakQwERTqufaeZTvV4XzWbTEOzyTJfJHx8fWd1+0GK/VXcPAbobUCR2mUMSm+kq7ydp14Cd/RStTbYKxz7zeEauk00bDAbiUGDjADI841S73RaHAvhI/kdCy0pycjgUwEfi37ZSqSRjYiix6TO695nHtBYjkoa5zCW5XC4dbxDjuLJvmBwJ/hmhDGujIOe0KPYF6gaxBp74lJhWw/eRfr9vRd3OSU3dEWvynCtZmEKiKOIhs6wZ2MWZV6tVjsUxr9c7P4FYwEAW5jzMvN6F26kDzZ8NKDFL+g8xgYhT4fYYXbMNueVeXddj1LkoX1qrTctfGfp8400inoT0GL4uGDe/Fi21sLItcQhEDVexfMHZK2zinMswrE+HQn45EAxKszgwO2KohtFAIKD/T/fa/ZAjEhQGQfiErR8/2RRROdMkBXkSXsqsIrTwF54fn3CDCBiPAAAAAElFTkSuQmCC') !important

.color-input-displayed
    position: absolute
.color-input
    position: relative
    display: flex
    flex-wrap: wrap
    margin-bottom: 20px
    .default-description
        margin-left: 45px
        height: 30px
        display: flex
        align-items: center
    .page-edit-aside-description
        margin-top: 4px
        width: 100%
    &__settings-handler
        height: 40px
        border-top: 1px solid #E7ECF1
        padding-left: 10px
        font-size: 12px
        display: flex
        align-items: center
        font-family: "Lato", serif
        color: #52565C
        cursor: pointer
    &__popular
        display: grid
        grid-template-columns: 1fr 1fr 1fr 1fr !important
        grid-gap: 5px
        margin: 10px
    &.tooltiped
        align-items: center
        .default-description
            margin-left: 0
            margin-right: 14px
        .color-input-displayed
            position: static
        .wrapper-alert
            .default-description
                margin-left: 0
                margin-right: 0

.color-input-picker
    display: flex
    position: absolute
    top: 0
    left: 0
    height: 220px
    box-shadow: 0 10px 20px rgba(213, 221, 230, 0.8)
    background-color: #fff
    overflow: hidden
    z-index: 2
    transition: .3s
    transform: scale(0)
    transform-origin: 15px 15px
    will-change: transform
    &_opened
        transform: scale(1)
        border-radius: 6px
    &__additional
        position: relative
        width: 0
        overflow: hidden
        transition: .3s
        &_opened
            width: 170px
.color-input.flat.relative
    .color-input-picker
        box-shadow: none
        position: relative
</style>
