export default {
    account_restored: "Konts tika atjaunots",
    restore_account: "Atjaunot kontu",
    authorization_platform: "Pieslēgties vai izveidot kontu",
    on_your: "Uz Jūsu",
    email: "e-pasta adresi",
    code_has_been_sent: "tika nosūtīts apstiprinājuma kods",
    enter_code: "Ievadiet kodu",
    code_is_valid: "Kods ir derīgs",
    minutes: "minūtes",
    request_new_code: "Jūs varēsiet pieprasīt kodu atkārtoti pēc",
    sec: "sekundēm",
    send_again: "Nosūtīt kodu atkārtoti",
    back: "Atpakaļ",
    next: "Tālāk",
    enter_password: "Ievadiet paroli",
    repeat_password: "Ievadiet paroli vēlreiz",
    forgot_password: "Aizmirsi paroli?",
    restore_access: "Atjaunot piekļuvi",
    enter_username: "Ievadiet e-pasta adresi vai tālruņa numuru",
    enter_email: "Ievadiet e-pasta adresi",
    create_new_password: "Izdomājiet jaunu paroli",
    select_language: "Izvēlieties valodu",
    select_currency: "Izvēlieties valūtu",
    language: "Valoda",
    currency: "Valūta",
    or: "vai",
    enter: "Pieslēgties",
    close: "Aizvērt",
    fields_filled: "Lauki ir aizpildīti!",
    fill_title: "Aizpildiet laukus",
    fill_descr: "Lai reģistrāciju pabeigtu, nepieciešams aizpildīt laukus",
    welcome: "Laipni lūdzam!",
    check_locale: " Vai mēs pareizi noteicām Jūsu valodu:",
    all_ok: "Jā, viss ir pareizi",
    no_change_language: "Nē, nomainīt valodu",
    set_password: "Paroles iestatīšana",
    set_password_description: "Izdomājiet paroli",
    understand_thanks: "Sapratu, paldies!",
    prerecording: "Iepriekšēja reģistrācija",
    prerecording_success: "Jūs esat pievienots iepriekšējās reģistrācijas sarakstā",
    congratulations: "Apsveicam!",
    back_to_menu: "Atpakaļ uz <b>Izvēlne</b>",
    //
    partners_program: "Partneru programma",
    become_a_partner: "Kļūt par partneri",
    products: "Mani produkti",
    certificates: "Sertifikāti",
    knowledge: "Zināšanu bāze",
    practices: "Izpildītās prakses",
    dictionary: "Vārdnīca",
    calendar: "Pasākumu kalendārs",
    news: "Jaunumi",
    history: "Pirkumu vēsture",
    localization: "Valoda",
    settings: "Iestatījumi",
    exit: "Iziet",
    projects: "Mani projekti",
    "expert.profile.expert_organization_cabinet": "Organizatora kabinets",
    "expert.profile.expert_producer_cabinet": "Producenta kabinets",
    "expert.profile.expert_cabinet": "Eksperta kabinets",
    "expert.profile.student_cabinet": "Skolēna kabinets",
    "expert.profile.create_cabinet": "Izveidot kabinetu",
    subaccounts: "Subkonti",
    school: "Atvērt savu skolu",
    dashboard: "Informācijas panelis",
    student_dashboard: "Manas mācības",
    logout: "Iziet no konta",
    auth_type_title: "Laipni lūdzam!",
    auth_type_btn_1: "Iestatīt paroli",
    auth_type_btn_2: "Atstāt pašreizējo autorizācijas veidu"
}
