<template>
    <div class="color-input builder-color-input" :class="{ disabled, flat, tooltiped }">
        <template v-if="tooltiped">
            <alert-with-question>
                <default-title class="mb-5"> {{ label }} </default-title>
                <slot></slot>
            </alert-with-question>

            <p
                v-if="label"
                style="color: #52565c"
                :style="{ fontSize: `${14}px`, lineHeight: `${16}px`, fontWeight: 400 }"
                class="default-description"
                @click="$emit('click')"
            >
                {{ label }}:
            </p>
        </template>

        <div class="builder-color-input-picker" :class="{ 'builder-color-input-picker_opened': isPickerOpen || flat }">
            <div class="builder-color-input-picker__main custom-scroll">
                <div v-if="usedColors && usedColors.length">
                    <div class="pl-10 -mb-8">
                        <default-description :size="10" class="ml-0">
                            {{ t("builder.previously_used") }}:
                        </default-description>
                    </div>

                    <div class="builder-color-input-picker__popular">
                        <ColorCircle
                            with-remove
                            @remove="
                                editor.chain().focus()
                                removeUsedColor(index)
                            "
                            @click="
                                hideColorInput(true)
                                $emit('update', $event)
                            "
                            v-for="(color, index) in usedColors"
                            :key="index"
                            :color="color"
                        />
                    </div>

                    <div class="px-10">
                        <default-divider color="#eee" />
                    </div>
                </div>
                <div class="builder-color-input-picker__popular">
                    <ColorCircle
                        @click="
                            hideColorInput(true)
                            $emit('update', $event)
                        "
                        v-for="(color, index) in popular"
                        :key="index"
                        :color="color"
                    />
                </div>
            </div>
            <div
                style="border-left: 1px solid #e7ecf1 !important"
                class="builder-color-input-picker__additional builder-color-input-picker__additional_opened"
            >
                <ChromePicker :value="localValue" @input="updateValue" />

                <DefaultInput
                    @focus.native.stop
                    ref="input"
                    @focus="handleFocus"
                    @blur="handleBlur"
                    v-model="localValue"
                    placeholder="HEX"
                />

                <flex-container class="px-10">
                    <button
                        @click="hideColorInput(true)"
                        class="builder-color-input-picker__button builder-color-input-picker__button_red"
                    >
                        <CloseIcon fill="#fff" />
                    </button>

                    <button
                        @click="handleSubmit()"
                        class="builder-color-input-picker__button builder-color-input-picker__button_green"
                    >
                        <CheckIcon fill="#fff" />
                    </button>
                </flex-container>
            </div>
        </div>
        <div class="color-input-displayed">
            <ColorCircle @click="isPickerOpen = true" :color="displayedColor" />
        </div>

        <p
            v-if="label && !tooltiped"
            style="color: #52565c"
            :style="{ fontSize: `${14}px`, lineHeight: `${16}px`, fontWeight: 400 }"
            class="default-description"
            @click="$emit('click')"
        >
            {{ label }}
        </p>
        <div v-if="description" class="page-edit-aside-description">
            {{ description }}
        </div>
    </div>
</template>

<script>
import { getPersonalSettings, updatePersonalSettings } from "@common-store/personal-settings"
import PopularColors from "@components/Builder/components/ColorInput/popular-colors"
import ColorCircle from "@components/Builder/components/ColorInput/ColorCircle.vue"
import FlexContainer from "@components/Containers/FlexContainer.vue"
import DefaultInput from "@components/Forms/DefaultInput.vue"
import DefaultDescription from "@components/Typography/DefaultDescription.vue"
import DefaultDivider from "@components/Typography/DefaultDivider.vue"
import CheckIcon from "@icons/CheckIcon.vue"

import { Chrome } from "vue-color"
import AlertWithQuestion from "@components/Alerts/AlertWithQuestion.vue"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
import { mapActions } from "vuex"
import { EventBus } from "~events"
import CloseIcon from "../../../../modules/Expert/views/Settings/components/CloseIcon.vue"

export default {
    name: "EditorColorInput",
    components: {
        DefaultDivider,
        DefaultDescription,
        DefaultInput,
        CheckIcon,
        CloseIcon,
        FlexContainer,
        DefaultTitle,
        AlertWithQuestion,
        ColorCircle,
        ChromePicker: Chrome
    },
    props: {
        displayedColor: {
            type: String,
            required: false
        },
        description: {
            type: String,
            required: false
        },
        tooltiped: {
            type: Boolean,
            required: false
        },
        label: {
            type: String,
            required: false
        },
        disabled: {
            type: Boolean,
            required: false
        },
        flat: {
            type: Boolean,
            required: false
        },
        isOpen: {
            type: Boolean,
            default: true
        },
        editor: {
            type: Object,
            default: null
        },
        usedKey: {
            type: String,
            default: "used_colors"
        }
    },
    model: {
        prop: "displayedColor",
        event: "update"
    },
    data() {
        return {
            isPickerOpen: false,
            isAdditionalOpen: this.isOpen,
            colors: "#7778A470",
            popular: PopularColors,
            localValue: this.displayedColor,
            usedColors: []
        }
    },
    created() {
        this.usedColors = this.getUsedColors()

        EventBus.$on(`update-settings:${this.usedKey}`, () => {
            this.usedColors = this.getUsedColors()
        })
    },
    methods: {
        ...mapActions(["user/fetchCabinets"]),
        handleClick(event) {
            event.preventDefault()
            this.$refs.input.$refs.input.focus()
            EventBus.$emit("update:color-modal", true)
        },
        handleFocus() {
            EventBus.$emit("update:color-modal", true)
        },
        handleBlur() {
            EventBus.$emit("update:color-modal", false)
        },
        updateValue(event) {
            if (
                this.displayedColor === "rgba(255,255,255,0)" ||
                this.displayedColor === "rgba(0,0,0,0)" ||
                this.displayedColor === "transparent"
            ) {
                this.localValue = event.hex
            } else {
                this.localValue = event.hex8
            }
        },
        removeUsedColor(index) {
            const colors = this.getUsedColors()

            colors.splice(index, 1)

            updatePersonalSettings([{ key: this.usedKey, value: colors }])
        },
        getUsedColors() {
            return getPersonalSettings(this.usedKey) ?? []
        },
        handleSubmit() {
            if (window.user) {
                const colors = [this.localValue, ...this.getUsedColors()]
                updatePersonalSettings([{ key: this.usedKey, value: colors }])
            }

            this.$emit("update", this.localValue)
            this.hideColorInput(true)
        },
        hideColorInput(program) {
            if (program) {
                this.$emit("hide")
            }
            this.isPickerOpen = false
        }
    },
    mounted() {
        /*window.addEventListener("mousedown", event => {
            if (!event.target.closest(".color-input")) {
                this.hideColorInput()
            }
        })*/
    },
    watch: {
        displayedColor(val) {
            this.localValue = val
        }
    }
}
</script>
<style lang="sass">
.builder-color-input
    .default-input__input
        height: 23px !important
        border-radius: 4px !important
        margin: 0 10px !important
        margin-bottom: 10px !important
        max-width: calc(100% - 20px) !important
        text-indent: 5px !important
        font-size: 10px !important
        text-align: center !important
    .vc-chrome
        box-shadow: none !important
        width: 150px !important
        padding: 10px !important
    .vc-chrome-fields-wrap
        display: none !important
        padding-top: 7px !important
    .vc-input__label
        display: none !important
.vc-chrome-saturation-wrap
    width: 150px !important
    padding: 4px 10px !important
    padding-bottom: 70% !important
    border-radius: 6px !important

.vc-chrome
    box-shadow: none !important
    width: 150px !important
    padding: 10px !important

.builder-color-input
    margin-bottom: 0 !important

.vc-chrome-toggle-btn
    display: none !important
.vc-chrome-body
    padding: 0 !important
    width: 150px !important
    margin-top: 10px

.vc-saturation-circle
    border: 2px solid #fff !important
    box-shadow: none !important

.vc-checkerboard
    background-image:  url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALUSURBVHgBvVdJbuJQEP04ZFIGWYmiJEJIZsOwCpyguUHnBnFOAH2ChBMEThC4AUdoTgC9YlpggYTEzmKe6fcsGwFNEwjDk8D/u6x6rvo12SbWQDqdli8vL/2TyeQntkGbzaZgLVOGtY51BssMrimPx5NcR6dtDcIQluGzszP54uJCNBoNYRIaV4fDIXq9nuh2u6LVaolOp6NBFh8Ohwmfz6dtTFwoFEJQ8H51dSXf3t4Ku91u3K9UKnPPOZ3OuX25XBYgFaPRSMM24na748v0S8tuFovFD5BGT05O5Pv7+ynpOqAnjo+PxenpqYL1Jwz4WPrc4g08+Hl9fa3e3d0JSZK+tHCVfDweC03TaH0clr/OPjdnMS3FOaq0kqTbgjpo/dHRkbpo+VQ7BCou4YeHB7FrkBxuD+dyufAccTabVXB5w5nuxNJlMHW/MVO4N6IGwfOC6FXo4m3O9Ct5rVaTkeu0+t0wDxuVKbNv3Nzc0OUhWi3l8/nn8/NzZZOU+S541nC3jGLkl/AGQVakQwERTqufaeZTvV4XzWbTEOzyTJfJHx8fWd1+0GK/VXcPAbobUCR2mUMSm+kq7ydp14Cd/RStTbYKxz7zeEauk00bDAbiUGDjADI841S73RaHAvhI/kdCy0pycjgUwEfi37ZSqSRjYiix6TO695nHtBYjkoa5zCW5XC4dbxDjuLJvmBwJ/hmhDGujIOe0KPYF6gaxBp74lJhWw/eRfr9vRd3OSU3dEWvynCtZmEKiKOIhs6wZ2MWZV6tVjsUxr9c7P4FYwEAW5jzMvN6F26kDzZ8NKDFL+g8xgYhT4fYYXbMNueVeXddj1LkoX1qrTctfGfp8400inoT0GL4uGDe/Fi21sLItcQhEDVexfMHZK2zinMswrE+HQn45EAxKszgwO2KohtFAIKD/T/fa/ZAjEhQGQfiErR8/2RRROdMkBXkSXsqsIrTwF54fn3CDCBiPAAAAAElFTkSuQmCC') !important

.color-input-displayed
    position: absolute
.color-input
    position: relative
    display: flex
    flex-wrap: wrap
    margin-bottom: 20px
    .default-description
        margin-left: 45px
        height: 30px
        display: flex
        align-items: center
        &.ml-0
            margin-left: 0
    &__settings-handler
        height: 40px
        border-top: 1px solid #E7ECF1
        padding-left: 10px
        font-size: 12px
        display: flex
        align-items: center
        font-family: "Lato", serif
        color: #52565C
        cursor: pointer

    &.tooltiped
        align-items: center
        .default-description
            margin-left: 0
            margin-right: 14px
        .color-input-displayed
            position: static
        .wrapper-alert
            .default-description
                margin-left: 0
                margin-right: 0

.builder-color-input-picker
    display: flex
    position: absolute
    top: 0
    left: 0
    margin-bottom: 0
    height: 235px
    border-radius: 50%
    box-shadow: 0 10px 20px rgba(213, 221, 230, 0.8)
    background-color: #fff
    overflow: hidden
    z-index: 2
    transition: .3s
    transform: scale(0)
    transform-origin: 15px 15px
    will-change: transform
    &__popular
        margin: 10px
        display: grid
        grid-template-columns: 1fr 1fr 1fr 1fr !important
        grid-gap: 5px
    &__main
        max-width: 165px
        max-height: 235px
        overflow: auto
    &__button
        height: 23px
        transition: .2s
        width: 100%
        border-radius: 2px
        border: 1px solid  #dadada
        &:nth-child(2)
            margin-left: 5px
        &_red
            background-color: #F65B5B
            &:hover
                background-color: #ee3b3b
        &_green
            background-color: #30C58F
            &:hover
                background-color: #28BA85
    &_opened
        transform: scale(1)
        border-radius: 6px
    &__additional
        position: relative
        width: 0
        overflow: hidden
        transition: .3s
        &_opened
            width: 170px
.color-input.flat.relative
    .builder-color-input-picker
        box-shadow: none
        position: relative
</style>
