<template>
    <div class="countries-container">
        <template v-if="isInputFormat()">
            <div class="date-label" v-if="label">
                {{ label }}
            </div>
            <flex-container class="input-container" style="grid-gap: 10px; margin-top: 5px">
                <DefaultDropdown
                    with-input
                    simple-input
                    scroll-active
                    class="full-width"
                    :cases="daysCases"
                    :active-case="days"
                    @update="days = $event"
                />
                <DefaultDropdown
                    with-input
                    simple-input
                    scroll-active
                    class="full-width"
                    :cases="monthsCases"
                    :active-case="months"
                    @update="months = $event"
                />
                <DefaultDropdown
                    with-input
                    simple-input
                    scroll-active
                    class="full-width"
                    :cases="yearsCases"
                    :active-case="years"
                    @update="years = $event"
                />
            </flex-container>
        </template>
        <DefaultDropdown
            v-else
            class="counties-dropdown"
            :label="(!multiple && label) || ''"
            :style-modifier="styleModifier"
            :active-case="value"
            :border-color="borderColor"
            flexible-label
            :required="required"
            ref="dropdown"
            :cases="[]"
        >
            <template v-if="tooltip" #tooltip>
                <alert-with-question>
                    <default-title
                        :weight="500"
                        font-family="Inter, sans-serif"
                        class="mb-5"
                        v-html="label"
                    ></default-title>
                    <default-description font-family="Inter, sans-serif" v-html="tooltip"></default-description>
                </alert-with-question>
            </template>
            <template #modal>
                <CustomCalendar
                    :allow-year-select="true"
                    :allow-double-click="false"
                    :select-date="activeCase"
                    @get="handleDate"
                />
            </template>
        </DefaultDropdown>
    </div>
</template>

<script>
import FlexContainer from "../Containers/FlexContainer.vue"
import AlertWithQuestion from "../Alerts/AlertWithQuestion.vue"
import CustomCalendar from "../CustomCalendar/CustomCalendar.vue"
import DefaultDropdown from "../Forms/DefaultDropdown.vue"
import DefaultDescription from "../Typography/DefaultDescription.vue"
import DefaultTitle from "../Typography/DefaultTitle.vue"
import LocalTranslator from "../../mixins/local-translator"
import moment from "moment"

import "moment/dist/locale/ru"
import "moment/dist/locale/uk"
import "moment/dist/locale/it"
import "moment/dist/locale/es"
import "moment/dist/locale/de"
import "moment/dist/locale/pl"
import "moment/dist/locale/fr"
import "moment/dist/locale/lv"

moment.locale(window.app_current_locale)

const currentYear = new Date().getFullYear()
const formatO = val => (val < 10 ? `0${val}` : val)

export default {
    components: {
        FlexContainer,
        CustomCalendar,
        DefaultDescription,
        DefaultDropdown,
        AlertWithQuestion,
        DefaultTitle
    },
    props: {
        label: {
            type: String,
            default: ""
        },
        format: {
            type: [Object, Array],
            default: null
        },
        validation: {
            type: [Object, Array],
            default: null
        },
        borderColor: {
            type: String,
            default: "#E0E0EC"
        },
        required: {
            type: Boolean,
            default: false
        },
        tooltip: {
            type: String,
            default: ""
        },
        activeCase: {
            type: [Object, Array, String, Number, Date],
            default: null
        },
        cases: {
            type: [Object, Array],
            default: null
        },
        withAbsent: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        styleModifier: {
            type: [String, Array],
            default: ""
        }
    },
    model: {
        prop: "activeCase",
        event: "update"
    },
    name: "DefaultDateDropdown",
    mixins: [LocalTranslator],
    data() {
        return {
            localKeys: "course",
            monthsCases: new Array(12).fill({ id: 0, value: 0 }).map((_, index) => formatO(index + 1)),
            daysCases: new Array(31).fill({ id: 0, value: 0 }).map((_, index) => formatO(index + 1)),
            yearsCases: new Array(151).fill(0).map((_, index) => currentYear + 50 - index)
        }
    },
    methods: {
        isInputFormat() {
            return this.format && this.format.date && this.format.date === "[DAY] [MONTH] [YEAR]"
        },
        handleDate($event) {
            this.$emit("update", $event.date)
            this.$refs.dropdown.hide()
        }
    },
    computed: {
        days: {
            get() {
                if (this.activeCase) {
                    return formatO(new Date(this.activeCase).getDate())
                }
                return ""
            },
            set(val) {
                const date = this.activeCase ? new Date(this.activeCase) : new Date()

                if (val <= 31) {
                    date.setDate(val)
                }

                this.$emit("update", date)
            }
        },
        months: {
            get() {
                if (this.activeCase) {
                    return formatO(new Date(this.activeCase).getMonth() + 1)
                }
                return ""
            },
            set(val) {
                const date = this.activeCase ? new Date(this.activeCase) : new Date()

                if (val <= 12) {
                    date.setMonth(val - 1)
                }

                this.$emit("update", date)
            }
        },
        years: {
            get() {
                if (this.activeCase) {
                    return new Date(this.activeCase).getFullYear()
                }
                return ""
            },
            set(val) {
                const date = this.activeCase ? new Date(this.activeCase) : new Date()
                date.setFullYear(val)

                this.$emit("update", date)
            }
        },
        value() {
            if (this.activeCase) {
                return moment(this.activeCase).format(this?.format?.date || "DD MMMM, YYYY")
            }
            return this.activeCase
        }
    }
}
</script>

<style lang="sass" scoped>
.date-label
    font-size: 12px
    margin-left: 30px
    margin-bottom: 5px
.full-width
    width: 100%
.input-container
    @media (max-width: 410px)
        flex-direction: column !important
</style>
