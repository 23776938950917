<template>
    <modal-container :min-width="440" centred class="button-editor relative">
        <CloseButtonSm @close="$emit('close')" class="absolute top-10 right-10" />
        <div class="full-width">
            <flex-container class="mb-20" align="center">
                <alert-with-question>
                    <default-title class="mb-5" :size="14" :weight="600" :line-height="18">
                        {{ title }}
                    </default-title>
                    <default-description class="mb-10">
                        <span v-html="t('builder.add_button_descr_1')"></span>
                    </default-description>

                    <default-description>
                        <span v-html="t('builder.add_button_descr_2')"></span>
                    </default-description>
                </alert-with-question>
                <default-title :size="16" :weight="500" :line-height="21">
                    {{ title }}
                </default-title>
            </flex-container>

            <DefaultDropdown
                @update="handleType"
                :label="t('builder.button_type')"
                class="mb-20"
                v-model="active"
                :cases="cases"
            />

            <DefaultInput v-model="text" class="mb-20" :label="t('builder.text_on_button')" />

            <div class="mb-20" v-if="active">
                <DefaultInput v-if="active.id === 1" v-model="link" :label="t('builder.link_on_button')" />

                <DefaultDropdown
                    @update="handleGroup"
                    :placeholder="t('create-account.select_a_group')"
                    v-model="group"
                    v-else-if="active.id === 2"
                    :loading="loading"
                    :cases="groups"
                />

                <DefaultDropdown
                    @update="handleBlock"
                    :placeholder="t('builder.block')"
                    v-model="block"
                    v-else-if="active.id === 3 || active.id === 4"
                    :loading="loading"
                    :cases="blocks"
                />
            </div>

            <flex-container justify="space-between" class="mb-30">
                <flex-container align="center">
                    <default-description class="mr-14 mt-5"> {{ t("builder.button_color") }}: </default-description>
                    <ColorInput v-model="background" />
                </flex-container>

                <flex-container class="mr-30" align="center">
                    <default-description class="mr-14 mt-5"> {{ t("builder.text_color") }}: </default-description>
                    <ColorInput v-model="color" />
                </flex-container>
            </flex-container>

            <flex-container>
                <DefaultButton
                    @click="$emit('close')"
                    background="cancel"
                    class="mr-10"
                    :width="190"
                    :height="45"
                    :text="t('expert.cancel')"
                />
                <DefaultButton
                    @click="addButton()"
                    background="green"
                    :width="190"
                    :height="45"
                    :text="t('expert.save')"
                />
            </flex-container>
        </div>
    </modal-container>
</template>

<script>
import ModalContainer from "@expert-components/Modals/ModalContainer.vue"
import AlertWithQuestion from "@components/Alerts/AlertWithQuestion.vue"
import FlexContainer from "@components/Containers/FlexContainer.vue"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
import DefaultDescription from "@components/Typography/DefaultDescription.vue"
import DefaultInput from "@components/Forms/DefaultInput.vue"
import ColorInput from "@components/Builder/components/ColorInput/ColorInput.vue"
import DefaultButton from "@components/Buttons/DefaultButton.vue"
import CloseButtonSm from "@components/Buttons/CloseButtonSm.vue"
import DefaultDropdown from "@components/Forms/DefaultDropdown.vue"

import axios from "~axios"
import { EventBus, EventsList } from "~events"

const BUTTON = `<p style="text-align: center; "><a class="builder-button"  target="_blank" rel="noopener noreferrer nofollow" href="$link"><span style="background: $background; cursor: pointer; margin: 0px auto 10px; text-align: center; width: auto; padding: 10px 20px; border-radius: 8px; display: inline-block; color: $color; font-family: $family; font-weight: 400">$text</span></a></p>`

export default {
    props: {
        editor: {
            type: Object,
            default: () => {}
        },
        button: {
            type: [Boolean, String],
            default: false
        }
    },
    name: "ButtonEditor",
    components: {
        DefaultDropdown,
        CloseButtonSm,
        DefaultButton,
        ColorInput,
        DefaultInput,
        DefaultDescription,
        DefaultTitle,
        FlexContainer,
        AlertWithQuestion,
        ModalContainer
    },
    data() {
        return {
            text: "",
            link: "",
            color: "#fff",
            family: "Inter",
            background: "#3965FF",
            cases: [],
            active: null,
            groups: [],
            group: null,
            blocks: [],
            block: null,
            loading: false
        }
    },
    created() {
        if (typeof this.button === "string") {
            const container = document.createElement("div")

            container.innerHTML = this.button

            const link = container.firstChild
            const span = link.firstChild

            this.link = link.href
            this.text = span.innerText

            this.color = span.style.color || "#fff"
            this.background = span.style.background || "#3965FF"
        }

        this.cases = [
            {
                title: this.t("builder.link"),
                id: 1
            },
            {
                title: this.t("builder.add_closed_group_link"),
                id: 2
            }
        ]

        if (this?.$route?.name === "LessonEditorConstructor") {
            this.cases.push({
                title: this.t("builder.add_block_lesson_link"),
                id: 3
            })
        }

        if (this?.$route?.name === "CourseDetailSettingsPublicPages") {
            this.cases.push({
                title: this.t("builder.add_block_link"),
                id: 4
            })
        }

        this.active = this.cases[0]
    },
    computed: {
        title() {
            return this.t(typeof this.button === "string" ? "builder.edit_button" : "builder.add_button")
        }
    },
    methods: {
        handleBlock(unit) {
            this.link = `#unit-${unit.id}`
        },
        handleGroup(group) {
            this.link = `/group-access/${group.id}`
        },
        async handleType(type) {
            if (type.id === 1) {
                return
            }

            const getBlockTitle = id => {
                switch (id) {
                    case 1: {
                        return this.t("create-course.audio")
                    }
                    case 2: {
                        return this.t("create-course.video")
                    }
                    case 3: {
                        return this.t("create-course.text_image_code_insert")
                    }
                    case 4: {
                        return this.t("create-course.file")
                    }
                    case 5: {
                        return this.t("create-course.practice")
                    }
                    case 6: {
                        return this.t("course.course_card.comments")
                    }
                    case 7: {
                        return this.t("create-course.practices_list")
                    }
                    default: {
                        return ""
                    }
                }
            }

            try {
                if (type.id === 2 && !this.groups.length) {
                    this.loading = true

                    const result = await axios.get(`/closed-groups`)
                    this.groups = result.data.data
                }

                if (type.id === 3 && !this.blocks.length) {
                    const { lessonId, courseId } = this.$route.params
                    const { section } = this.$route.query
                    this.loading = true

                    const result = await axios.get(`/courses/${courseId}/lessons-new/${lessonId}`)
                    const lesson = result.data.data

                    this.blocks = lesson.sections[section || 0].info_units.map((unit, index) => ({
                        ...unit,
                        title: unit.title || `${this.t("builder.block")} #${index + 1} (${getBlockTitle(unit.type_id)})`
                    }))
                }

                if (type.id === 4 && !this.blocks.length) {
                    const { courseId } = this.$route.params
                    this.loading = true

                    const result = await axios.get(`/courses/${courseId}/`)
                    const course = result.data.data

                    this.blocks = course.info_units.map((unit, index) => ({
                        ...unit,
                        title: unit.title || `${this.t("builder.block")} #${index + 1} (${getBlockTitle(unit.type_id)})`
                    }))
                }
            } catch (error) {
                EventBus.$emit(
                    EventsList.SHOW_EVENT_ERROR,
                    error?.response?.data?.message || error?.response?.data?.error || error
                )
            } finally {
                this.loading = false
            }
        },
        addButton() {
            let content = BUTTON.replaceAll("$color", this.color)
                .replaceAll("$background", this.background)
                .replaceAll("$link", this.link)
                .replaceAll("$text", this.text)
                .replaceAll("$family", this.family)

            if (this.active && (this.active.id === 3 || this.active.id === 4)) {
                content = content.replaceAll('target="_blank"', 'target="_self"')
            }

            if (typeof this.button === "boolean") {
                this.editor.commands.insertContent(content)
            } else {
                this.editor.commands.selectParentNode()

                setTimeout(() => {
                    const { from, to } = this.editor.state.selection

                    this.editor.commands.insertContentAt({ from, to }, content, {
                        updateSelection: true,
                        parseOptions: {
                            preserveWhitespace: "full"
                        }
                    })
                })
            }

            this.$emit("close")
        }
    }
}
</script>

<style scoped lang="sass">
.button-editor
    z-index: 10000 !important
</style>
