<template>
    <div @wheel.self.prevent @click="handleClose" :class="{ black: true }" class="overlay-layout">
        <div v-if="$slots.custom" :style="overlayStyle()" :class="{ pushed: isVisible }" class="overlay-content-custom">
            <slot name="custom"></slot>
        </div>
        <div v-else :style="overlayStyle()" :class="{ pushed: isVisible }" class="overlay-content">
            <div class="overlay-content-head">
                <flex-container align="center">
                    <CloseButtonLg class="overlay-layout-btn" @close="handleClose($event, true)" relative />
                    <default-title
                        text-color="#323030"
                        class="ml-30"
                        :weight="500"
                        :size="$screenWidth < 480 ? 14 : 16"
                        font-family="Inter, sans-serif"
                        v-if="title"
                    >
                        {{ title }}
                    </default-title>
                </flex-container>
                <slot name="right"></slot>
            </div>
            <div class="overlay-content-scroll custom-scroll" :style="contentStyle()">
                <div class="overlay-content-wrapper custom-scroll" :style="contentWrapperStyle()">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { toPx } from "@components/Builder/widget/utils/style"
import FlexContainer from "@components/Containers/FlexContainer.vue"
import CloseButtonLg from "@components/Buttons/CloseButtonLg.vue"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"

export default {
    name: "OverlayLayout",
    props: {
        title: {
            type: String,
            default: ""
        },
        fullHeight: {
            type: Boolean,
            default: true
        },
        maxWidth: {
            type: [String, Number],
            default: ""
        },
        minWidth: {
            type: [String, Number],
            default: ""
        },
        width: {
            type: String,
            default: ""
        },
        contentWrapper: {
            type: Boolean,
            default: true
        },
        backgroundColor: {
            type: String,
            default: ""
        },
        minContentWrapperHeight: {
            type: [String, Number],
            default: ""
        },
        contentWrapperHeight: {
            type: [String, Number],
            default: ""
        }
    },
    components: { DefaultTitle, CloseButtonLg, FlexContainer },
    data() {
        return {
            isVisible: false
        }
    },
    mounted() {
        document.body.classList.add("no-overflow")
        setTimeout(() => {
            this.isVisible = true
        }, 100)
    },
    beforeDestroy() {
        document.body.classList.remove("no-overflow")
    },
    methods: {
        overlayStyle() {
            let style = {}
            if (this.maxWidth) {
                if (typeof this.maxWidth === "string") {
                    style = {
                        width: this.maxWidth,
                        minWidth: 0
                    }
                } else {
                    style = {
                        width: toPx(this.maxWidth),
                        minWidth: 0
                    }
                }
            }

            if (this.minWidth) {
                if (typeof this.minWidth === "string") {
                    style.width = this.minWidth
                } else {
                    style.width = toPx(this.minWidth)
                }
            }

            if (this.width) {
                if (typeof this.minWidth === "string") {
                    style.width = this.width
                } else {
                    style.width = toPx(this.width)
                }
            }
            return style
        },
        contentStyle() {
            let style = {}
            if (this.backgroundColor) {
                style.backgroundColor = this.backgroundColor
            }
            if (this.fullHeight) {
                style.height = "100%"
            }
            return style
        },
        contentWrapperStyle() {
            let style = {}

            if (!this.contentWrapper) {
                style.marginLeft = "0px"
                style.marginBottom = "0px"
                style.padding = "20px"
            }

            if (this.fullHeight) {
                style.height = "100%"
            } else if (this.contentWrapperHeight) {
                style.height =
                    typeof this.contentWrapperHeight === "number"
                        ? `${this.contentWrapperHeight}px`
                        : this.contentWrapperHeight
            }
            if (this.minContentWrapperHeight) {
                style.minHeight =
                    typeof this.contentWrapperHeight === "number"
                        ? `${this.minContentWrapperHeight}px`
                        : this.minContentWrapperHeight
            }
            return style
        },
        handleClose(event, force) {
            const close = () => {
                if (event) {
                    event.stopPropagation()
                }

                this.isVisible = false
                setTimeout(() => {
                    this.$emit("back")
                    this.$emit("close")
                    this.$emit("hide")
                }, 400)
            }

            if (force) {
                close()
                return
            }

            if (
                (event &&
                    event.target &&
                    event.target.classList &&
                    (event.target.classList.contains("overlay-layout") ||
                        event.target.classList.contains("overlay-layout-btn"))) ||
                !event
            ) {
                const selection = window.getSelection()

                if (
                    selection &&
                    selection.type === "Range" &&
                    selection.anchorNode &&
                    selection.anchorNode.parentNode.closest(".overlay-content-wrapper")
                ) {
                    return
                }

                close()
            }
        }
    }
}
</script>

<style scoped lang="sass">
.full-height
    height: 100%
.overlay-content-head
    height: 60px
    background-color: #FCFCFD
    display: flex
    justify-content: space-between
    align-items: center
    padding: 10px 16px 10px 20px
    @media (max-width: 780px)
        padding: 10px 16px 10px 20px
    .close-lg
        &:after
            content: ''
            display: block
            height: 40px
            background-color: #F3F3F3
            width: 1px
            position: absolute
            right: -10px
            top: 50%
            transform: translateY(-50%)
            margin-left: 10px
.overlay-content-scroll
    overflow: auto
    max-height: 100vh
    flex: 1
.overlay-content-wrapper
    max-height: calc(100% - 60px)
    margin-bottom: 60px
    margin-left: 60px
    padding: 20px 60px 20px 0
    overflow: auto
    @media (max-width: 780px)
        padding: 20px 16px 68px 0
        margin-left: 16px
    @media (max-width: 480px)
        padding: 20px 0 68px
        margin-left: 0

.overlay-layout
    position: fixed
    overflow: hidden
    align-items: flex-start
    display: flex
    justify-content: flex-end
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 999999
    background-color: transparent
    &.black
        transition: .2s
        background-color: #32303066
    &.blured
        transition: 0s
        backdrop-filter: blur(2px) !important
.overlay-content, .overlay-content-custom
    display: flex
    flex-direction: column
    height: 100%
    transition: .3s
    width: 60%
    min-width: 1120px
    background: #F0F3F4
    transform: translateX(100%)
    @media (max-width: 1120px)
        width: 100%
        min-width: 0
    &.pushed
        transform: translateX(0)
.overlay-content-custom
    background: none
</style>
