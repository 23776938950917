<template>
    <div
        :style="questionStyle()"
        class="practice-question"
        :class="{ 'practice-question_gaps practice-question_gaps_pointer': readonly }"
    >
        <PracticeQuestionInfo :number="questionNumber" :name="question.name" :question="question" :quiz="quiz" />

        <PracticeQuestionDefaultOptions
            v-if="interactive"
            :attempt="attempt"
            :quiz="quiz"
            :question="question"
            @next="$emit('next')"
        />
        <div v-else class="mb-10"></div>

        <FilesNewView class="mb-10" v-if="question.files && question.files.length" :files="question.files" />

        <div
            v-if="isShowAnswers()"
            class="practice-question__answers"
            :class="{ 'practice-question__answers_disabled': disabledByTimer() }"
        >
            <custom-collapse v-if="isAllAnswersChoice()" :is-open="getAvailableAnswers().length">
                <div v-if="isAllAnswersChoice() && interactive" class="practice-question__all-answers mb-20">
                    <transition-group name="list-complete" tag="p">
                        <span
                            v-html="answer.text"
                            class="list-complete-item"
                            :key="answer.id"
                            v-for="answer in getAvailableAnswers()"
                        >
                        </span>
                    </transition-group>
                </div>
            </custom-collapse>

            <div
                class="practice-question__statement-container"
                :key="index"
                v-for="(statement, index) in question.statements"
            >
                <div class="practice-question__statement">
                    <flex-container style="flex-wrap: wrap">
                        <img
                            style="max-height: 250px; max-width: 100%"
                            v-if="statement.image"
                            :src="statement.image.url"
                            alt="image"
                        />
                        <p style="line-height: 25px; position: relative" :class="statement.image ? 'm-20' : 'mt-10'">
                            <component ref="processed" v-if="processed[index]" v-bind:is="processed[index]">
                            </component>
                        </p>
                    </flex-container>
                </div>
            </div>
        </div>

        <PracticeCorrectStatusAlert
            v-if="!interactive"
            :correct-answers="typeShow() === 'full_information' && isIncorrect && isIncorrect() && getCorrectAnswers()"
            :is-fill-gaps="typeShow() === 'full_information' && isIncorrect && isIncorrect()"
            :is-correct="isCorrect"
            :is-incorrect="isIncorrect"
            :is-skipped="isSkipped"
            :is-not-started="isNotStarted"
        />

        <PracticeQuestionComment v-if="isDisplayComment" :question="question" />

        <PracticeActionsContainer
            v-if="interactive && !didUpdateAction"
            :question="question"
            :quiz="quiz"
            v-on="$listeners"
        />
    </div>
</template>

<script>
import CustomCollapse from "@components/Collapse/CustomCollapse.vue"
import PracticeCorrectStatusAlert from "@components/Practice/PracticeQuestions/components/PracticeCorrectStatusAlert.vue"
import { Debouncer } from "@helpers"
import shuffle from "lodash.shuffle"
import FlexContainer from "../../Containers/FlexContainer"
import AttemptResultExpertWidget from "../common/result/AttemptResultExpertWidget"
import AttemptResultStudentWidget from "../common/result/AttemptResultStudentWidget"
import PracticeMixin from "./_mixin"
import PracticeActionsContainer from "./components/PracticeActionsContainer.vue"
import PracticeQuestionDefaultOptions from "./components/PracticeQuestionDefaultOptions"
import FilesNewView from "../../Files/FilesNewView"
import FillGapsInput from "./components/PracticeFillGapsInput"
import FillGapsDropdown from "./components/PracticeFillGapsDropdown"
import PracticeQuestionInfo from "./components/PracticeQuestionInfo"

export default {
    props: {
        question: {
            type: Object,
            default: () => {}
        }
    },
    mixins: [PracticeMixin],
    components: {
        PracticeCorrectStatusAlert,
        CustomCollapse,
        AttemptResultExpertWidget,
        AttemptResultStudentWidget,
        PracticeActionsContainer,
        PracticeQuestionInfo,
        FillGapsDropdown,
        FillGapsInput,
        FilesNewView,
        PracticeQuestionDefaultOptions,
        FlexContainer
    },
    name: "PracticeFillGapsAnswer",
    data() {
        return {
            processed: [],
            canCallout: false,
            Debouncer: new Debouncer()
        }
    },
    created() {
        if (this.interactive && this.isAllAnswersChoice() && this.question.statements.length) {
            const answers = []

            this.question.statements.forEach(statement => {
                answers.push(...statement.answers)
                statement.answers = []
            })

            this.question.statements[0].answers = shuffle(answers)
        }
        this.question.statements.forEach((statement, index) => {
            this.processed[index] = this.processHtml(statement)
        })

        setTimeout(() => {
            this.canCallout = true
        }, 100)
    },
    methods: {
        processHtml(statement) {
            let html = ""
            const text = document.createElement("div")
            text.innerHTML = statement.text
            html = statement.text
            text.querySelectorAll(`span[data-id]`).forEach(span => {
                const dataset = span.dataset
                const { gapId, id } = dataset

                let component =
                    parseInt(gapId) === 1
                        ? `
                            <PracticeFillGapsInput
                                @update="setInput(statement, ${id}, $event)"
                                :cases="getCases(statement, ${id})"
                                :result="getAnswerResult(${id})"
                                :type-show="typeShow()"
                                :interactive="interactive"
                                :viewable="viewable"
                                :readonly="readonly"
                            />
                        `
                        : `
                            <PracticeFillGapsDropdown
                                ref="dropdown"
                                @update="setInput(statement, ${id}, $event)"
                                @delete="deleteInput(statement, ${id})"
                                :cases="getCases(statement, ${id})"
                                :answer-group="getAnswerGroup(statement, ${id})"
                                :result="getAnswerResult(${id})"
                                :type-show="typeShow()"
                                :is-flat="isFlat(statement, ${id})"
                                :is-all-answers-choice="isAllAnswersChoice(statement)"
                                :interactive="interactive"
                                :viewable="viewable"
                                :readonly="readonly"
                            />
                `

                if (this.expertView) {
                    component += `
                        <AttemptResultExpertWidget
                            class="attempt-widget attempt-widget_expert"
                            v-bind="widget()"
                            :statement="statement"
                            :commentable="getAnswerGroup(statement, ${id})"
                            :answer-group-id="parseInt(${id})"
                            :correct-answers="getCorrectAnswers(${id})"
                        />
                    `
                }
                if (!this.expertView) {
                    component += `

                    <AttemptResultStudentWidget
                        v-if="getAnswerGroup(statement, ${id}) && getAnswerGroup(statement, ${id}).comments_count || (typeShow() === 'full_information' && !interactive)"
                        class="attempt-widget"
                        :attempt-id="attempt && attempt.id"
                        :correct-answers="getCorrectAnswers(${id})"
                        :commentable-id="
                            getAnswerGroup(statement, ${id}) && getAnswerGroup(statement, ${id}).comments_count
                                ? getAnswerGroup(statement, ${id}).id
                                : null
                        "
                        :comments-count="getAnswerGroup(statement, ${id}) ? getAnswerGroup(statement, ${id}).comments_count : 0 "
                        :commentable-type="'answer_group_id'"
                    />
                `
                }

                html = html.replaceAll(span.outerHTML, component)
            })

            const vm = this

            return {
                template:
                    "<div class='text-view' style='white-space: initial; font-size: 1rem; line-height: 1.5'>" +
                    html +
                    "</div>",
                data() {
                    return {
                        interactive: vm.interactive,
                        statement,
                        attempt: vm.attempt,
                        viewable: vm.viewable,
                        readonly: vm.readonly
                    }
                },
                methods: {
                    getCases: vm.getCases,
                    getAnswerResult: vm.getAnswerResult,
                    getAnswerGroup: vm.getAnswerGroup,
                    typeShow: vm.typeShow,
                    setInput: vm.setInput,
                    deleteInput: vm.deleteInput,
                    widget: vm.widget,
                    getCorrectAnswers: vm.getCorrectAnswers,
                    isFlat: vm.isFlat,
                    isAllAnswersChoice: vm.isAllAnswersChoice
                }
            }
        },
        isFlat(statement, id) {
            return this.getAnswerGroup(statement, id)?.display_type_id === 2
        },
        deleteInput(statement, id) {
            this.forceUpdateAction()
            this.getAnswerGroup(statement, id).active = undefined

            if (this.canCallout) {
                this.$emit("updateActions")
                this.$emit("callout")
            }

            if (this.$refs.processed) {
                if (Symbol.iterator in Object(this.$refs.processed)) {
                    for (const ref of this.$refs.processed) {
                        ref.$forceUpdate()
                        if (ref.$refs["dropdown"]) {
                            ref.$refs["dropdown"].$forceUpdate()
                        }
                    }
                }
            }

            this.$forceUpdate()
        },
        setInput(statement, id, event) {
            if (!this.interactive) {
                return
            }
            this.question.is_allowed = true
            this.forceUpdateAction()
            this.getAnswerGroup(statement, id).active = event

            if (this.canCallout) {
                this.$emit("callout")
            }

            if (Symbol.iterator in Object(this.$refs.processed)) {
                for (const ref of this.$refs.processed) {
                    ref.$forceUpdate()
                    if (ref.$refs["dropdown"]) {
                        ref.$refs["dropdown"].$forceUpdate()
                    }
                }
            }

            this.$forceUpdate()
        },
        getAnswerGroup(statement, id) {
            const group = statement.answer_groups.find(answer_group => answer_group.id === parseInt(id)) || {}

            return group
        },
        getCorrectAnswers(id) {
            if (this.typeShow() !== "full_information") {
                return null
            }

            return this.question.answers
                .filter(answer => {
                    if (id) {
                        return answer.quiz_answer_group_id === parseInt(id) && answer.is_correct
                    }

                    return answer.is_correct
                })
                .map(answer => answer.text)
        },
        getAnswerResult(id) {
            if (!this.results) {
                return null
            }

            if (this.interactive && !this.viewable) {
                return null
            }

            return this.results.find(r => r.quiz_answer_group_id === parseInt(id))
        },
        getAvailableAnswers() {
            const activeAnswers = []
            const answers = []
            const inputAnswerGroups = []

            for (const statement of this.question.statements) {
                activeAnswers.push(...statement.answer_groups.map(group => group?.active?.answer_id))
                for (const answerGroup of statement.answer_groups) {
                    if (answerGroup.type_id === 1) {
                        inputAnswerGroups.push(answerGroup.id)
                    }
                }
            }

            for (const statement of this.question.statements) {
                answers.push(
                    ...statement.answers.filter(
                        answer =>
                            !this.isFlat(statement, answer.quiz_answer_group_id) &&
                            !activeAnswers.includes(answer.id) &&
                            !inputAnswerGroups.includes(answer.quiz_answer_group_id)
                    )
                )
            }

            return answers
        },
        getCases(statement, id) {
            let answers = this.getAnswerGroup(statement, id)?.answers || []
            if (this.isAllAnswersChoice(statement) && !this.isFlat(statement, id)) {
                answers = this.getAvailableAnswers(statement)
            }
            return answers.map(answer => ({ ...answer, text: answer.text || " " }))
        }
    }
}
</script>
<style lang="sass">
.attempt-widget
    margin-right: 0
    display: inline-block
    transform: translateY(-2px) !important
    @media (max-width: 540px)
        position: static !important

    .attempt-result-widget-handler
        transform: translateY(8px)

    &_expert .attempt-result-widget-handler
        margin-left: 5px
        margin-right: 5px
        transform: translateY(-10px)
.practice-question
    &__statement-container
        &:not(&:last-child)
            margin-bottom: 20px

    &__statement
        box-shadow: 0 0 15px rgba(128, 158, 191, 0.15)
        border-radius: 7px
        padding: 10px
        background: #fff

        img
            border-radius: 7px

        .text
            margin: 20px
            line-height: 130%

    &_gaps
        &_pointer
            .default-dropdown__modal, .fill-gaps-input
                pointer-events: none
</style>
