<template>
    <div class="editor-menu-container" :class="{ isIpad: isIpad() }">
        <div class="editor-menu" :class="{ 'rounded-bl-none': fontOpen }">
            <EditorFontStyle
                :is-open="fontOpen"
                @toggle:mobile="fontOpen = !fontOpen"
                @handle:open="fontOpen = $event"
                :editor="editor"
            />

            <div class="divider"></div>

            <EditorHeading :editor="editor" />

            <div class="divider"></div>

            <EditorTextAlign :editor="editor" />

            <div class="divider"></div>

            <EditorLists :editor="editor" />

            <div class="divider"></div>

            <button
                class="editor-button editor-button_fill"
                @click="editor.chain().focus().toggleBold().run()"
                :class="{ active: editor.isActive('bold') }"
            >
                <BoldIcon :data-tippy-content="t && t('builder.bold')" />
            </button>
            <button
                class="editor-button editor-button_fill"
                @click="editor.chain().focus().toggleItalic().run()"
                :class="{ active: editor.isActive('italic') }"
            >
                <ItalicIcon :data-tippy-content="t('builder.italic')" />
            </button>

            <button
                class="editor-button editor-button_fill"
                @click="editor.chain().focus().toggleUnderline().run()"
                :class="{ active: editor.isActive('underline') }"
            >
                <UnderlineIcon :data-tippy-content="t('builder.underline')" />
            </button>

            <button
                class="editor-button editor-button_fill"
                @click="editor.chain().focus().toggleStrike().run()"
                :class="{ active: editor.isActive('strike') }"
            >
                <StrikethroughIcon :data-tippy-content="t('builder.strike')" />
            </button>

            <button
                @click="editor.chain().focus().toggleBlockquote().run()"
                class="editor-button editor-button_quote editor-button_fill"
            >
                <svg :data-tippy-content="t('builder.quote')" viewBox="0 0 24 24" width="20">
                    <path
                        d="M4.58341 17.3211C3.55316 16.2275 3 15 3 13.0104C3 9.51092 5.45651 6.37372 9.03059 4.82324L9.92328 6.20085C6.58804 8.00545 5.93618 10.3461 5.67564 11.8221C6.21263 11.5444 6.91558 11.4467 7.60471 11.5106C9.40908 11.6778 10.8312 13.1591 10.8312 15C10.8312 16.933 9.26416 18.5 7.33116 18.5C6.2581 18.5 5.23196 18.0096 4.58341 17.3211ZM14.5834 17.3211C13.5532 16.2275 13 15 13 13.0104C13 9.51092 15.4565 6.37372 19.0306 4.82324L19.9233 6.20085C16.588 8.00545 15.9362 10.3461 15.6756 11.8221C16.2126 11.5444 16.9156 11.4467 17.6047 11.5106C19.4091 11.6778 20.8312 13.1591 20.8312 15C20.8312 16.933 19.2642 18.5 17.3312 18.5C16.2581 18.5 15.232 18.0096 14.5834 17.3211Z"
                    ></path>
                </svg>
            </button>

            <button class="editor-button editor-button_tooltip editor-button_fill" @click="makeTooltip">
                <span style="width: 20px; height: 20px; display: block; margin: 0 auto" data-tippy-content="Tooltip">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.5 5.1399C12.0139 5.1399 11.5486 5.20222 11.1042 5.32688C10.6597 5.43767 10.1944 5.62465 9.70833 5.88782L9 3.95568C9.51389 3.66482 10.0972 3.43629 10.75 3.27008C11.4167 3.09003 12.0972 3 12.7917 3C13.625 3 14.3125 3.11773 14.8542 3.35319C15.3958 3.57479 15.8264 3.85873 16.1458 4.20499C16.4653 4.55125 16.6875 4.93214 16.8125 5.34765C16.9375 5.76317 17 6.16482 17 6.55264C17 7.02355 16.9097 7.44599 16.7292 7.81995C16.5625 8.19392 16.3472 8.54017 16.0833 8.85874C15.8194 9.17729 15.5347 9.48201 15.2292 9.77286C14.9236 10.0499 14.6389 10.3407 14.375 10.6454C14.1111 10.9363 13.8889 11.2479 13.7083 11.5803C13.5417 11.9128 13.4583 12.2867 13.4583 12.7022C13.4583 12.7715 13.4583 12.8546 13.4583 12.9515C13.4583 13.0346 13.4653 13.1177 13.4792 13.2008H11.3125C11.2847 13.0623 11.2639 12.9169 11.25 12.7646C11.2361 12.5983 11.2292 12.446 11.2292 12.3075C11.2292 11.8504 11.3056 11.4418 11.4583 11.0817C11.6111 10.7216 11.8056 10.3892 12.0417 10.0845C12.2778 9.77978 12.5278 9.49585 12.7917 9.2327C13.0694 8.96953 13.3264 8.70638 13.5625 8.44322C13.7986 8.18006 13.9931 7.90997 14.1458 7.63297C14.2986 7.35596 14.375 7.05126 14.375 6.71885C14.375 6.26178 14.2153 5.88782 13.8958 5.59696C13.5903 5.29224 13.125 5.1399 12.5 5.1399ZM14.125 16.3587C14.125 16.8435 13.9653 17.2382 13.6458 17.543C13.3264 17.8476 12.9306 18 12.4583 18C12 18 11.6042 17.8476 11.2708 17.543C10.9514 17.2382 10.7917 16.8435 10.7917 16.3587C10.7917 15.874 10.9514 15.4792 11.2708 15.1745C11.6042 14.856 12 14.6967 12.4583 14.6967C12.9306 14.6967 13.3264 14.856 13.6458 15.1745C13.9653 15.4792 14.125 15.874 14.125 16.3587Z"
                            fill="currentColor"
                        />
                        <rect x="4" y="8" width="1" height="7" fill="currentColor" />
                        <rect x="1" y="12" width="1" height="7" transform="rotate(-90 1 12)" fill="currentColor" />
                    </svg>
                </span>
            </button>

            <div class="divider"></div>

            <button class="editor-button editor-button_fill" @click="setLink">
                <span
                    style="width: 20px; height: 20px; display: block; margin: 0 auto"
                    :data-tippy-content="t('builder.link') + ' ^⇑k'"
                >
                    <LinkIcon v-if="!editor.isActive('link')" />
                    <UnlinkIcon v-else :data-tippy-content="t('builder.remove_link')" />
                </span>
            </button>

            <!--<button @click="editor.chain().focus().toggleDetail().run()">Detail</button>-->
        </div>

        <custom-collapse :is-open="fontOpen">
            <div class="editor-menu-font-style" v-if="isIpad()">
                <EditorFontStyleContent :editor="editor" />
            </div>
        </custom-collapse>
    </div>
</template>

<script>
import CustomCollapse from "@components/Collapse/CustomCollapse.vue"
import EditorFontStyle from "@components/Editor/components/EditorFontStyle.vue"
import EditorFontStyleContent from "@components/Editor/components/EditorFontStyleContent.vue"
import EditorHeading from "@components/Editor/components/EditorHeading.vue"
import BoldIcon from "@icons/BoldIcon.vue"
import ItalicIcon from "@icons/ItalicIcon.vue"
import StrikethroughIcon from "@icons/StrikethroughIcon.vue"
import EditorTextAlign from "@components/Editor/components/EditorTextAlign.vue"
import UnderlineIcon from "@icons/UnderlineIcon.vue"
import LinkIcon from "../icons/LinkIcon.vue"
import UnlinkIcon from "../icons/UnlinkIcon.vue"

import tippy from "tippy.js"
import EditorLists from "@components/Editor/components/EditorLists.vue"
import checkDevice from "@mixins/checkDevice"

export default {
    components: {
        CustomCollapse,
        EditorFontStyleContent,
        EditorHeading,
        EditorFontStyle,
        EditorLists,
        UnderlineIcon,
        UnlinkIcon,
        LinkIcon,
        EditorTextAlign,
        StrikethroughIcon,
        ItalicIcon,
        BoldIcon
    },
    props: {
        editor: {
            type: Object,
            default: () => {}
        }
    },
    mixins: [checkDevice],
    name: "EditorMenu",
    data() {
        return {
            fontOpen: false
        }
    },
    mounted() {
        tippy(this.$el.querySelectorAll("[data-tippy-content]"), {
            appendTo: () => this.$el,
            arrow: false
        })
    },
    methods: {
        makeTooltip() {
            const { view, state } = this.editor
            const { from, to } = view.state.selection
            const title = state.doc.textBetween(from, to, "")

            this.editor
                .chain()
                .focus()
                .setTooltip({
                    title: title,
                    description: ""
                })
                .run()
        },
        setLink() {
            const previousUrl = this.editor.getAttributes("link").href
            const url = window.prompt("URL", previousUrl)

            if (url === null) {
                return
            }

            if (url === "") {
                this.editor.chain().focus().extendMarkRange("link").unsetLink().run()

                return
            }

            this.editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run()
        }
    }
}
</script>

<style lang="sass">
@import "../style/menu"
@import "../style/button"

.editor-menu-font-style
    background-color: #fff
    padding: 4px
    border-top: 1px solid #d5d7d9
    border-radius: 0px 0 6px 6px
    width: max-content
.editor-menu-container
    position: sticky
    top: 0
    z-index: 9999999999999999999999999999999
    filter: drop-shadow(0 2px 5px rgba(128, 158, 191, 0.25))
    &.isIpad
        .tippy-box
            display: none !important
</style>
